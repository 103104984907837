import React, { Component } from 'react';
import { createFromIconfontCN } from '@ant-design/icons';

import { PageWp } from '../../component/common';

import { apiGetUserProduct } from '../../api/action/product';

import '../../../css/page/homepage.scss';

import utils from '../../utils';

const MyIcon = createFromIconfontCN({ scriptUrl: utils.fixSysPageUrl('/ext/font_2592858_ic5pf6qqihn.js') });

class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allProduct: [],
    };
  }

  componentDidMount() {
    apiGetUserProduct().then((allProduct) => {
      this.setState({ allProduct });
    });
  }

  render() {
    const { allProduct } = this.state;

    return (
      <PageWp userContentCard={false}>
        <div className="product-wp">
          {allProduct
            .filter((i) => i.id !== utils.getCurProductId())
            .map(({ id, name, description, linkUrl, icon }) => (
              <a className="product-item" key={id} href={linkUrl} target="_blank" rel="noopener noreferrer">
                <MyIcon className="icon" type={icon} />
                <div className="name">{name}</div>
                <div className="desc">{description}</div>
              </a>
            ))}
        </div>
      </PageWp>
    );
  }
}

export default Homepage;
