import React, { forwardRef } from 'react';
import classNames from 'classnames';

const PolyphonicNode = forwardRef((props, ref) => {
  const {
    idx,
    item: { text, tag },
    active,
    onClick,
    onMouseUp,
    onNodeClick,
  } = props;

  return (
    <div className="rt-node rt-polyphonic" idx={idx} ref={ref} onClick={onClick} onMouseUp={onMouseUp}>
      <span className={classNames('re-polyphonic-text', { active })} onClick={onNodeClick}>
        {text}
      </span>
      <span className="rt-tag" onClick={onNodeClick}>
        {tag}
      </span>
    </div>
  );
});

PolyphonicNode.displayName = 'PolyphonicNode';

PolyphonicNode.defaultProps = {
  idx: 0,
  item: {},
  active: false,
  onClick: () => {},
  onMouseUp: () => {},
  onNodeClick: () => {},
};

export default PolyphonicNode;
