import queryString from 'query-string';
import moment from 'moment';
import copy from 'copy-to-clipboard';
import { message } from 'antd';

import verifyRule from './verify-rule';

import { ONLINE_PRODUCTID, TEST_PRODUCTID, AITOB_ORIGIN_COMMON_PART } from '../config/const';

const utils = {
  getCurProductId: () => (utils.isTestEnv() ? TEST_PRODUCTID : ONLINE_PRODUCTID),
  isTestEnv: () => window.location.origin.includes('virtualhuman') || window.location.origin.includes('local'),
  message,
  copy,
  verifyRule,
  getDefaultPageParams: () => ({ pageIndex: 1, pageSize: 20 }),
  getPaginationConfig: (pager = {}) => ({
    defaultPageSize: 20,
    pageSizeOptions: ['20', '50', '100'],
    showSizeChanger: true,
    // showQuickJumper: true,
    showTotal: (total) => `共 ${total} 条`,
    ...pager,
  }),
  getTextAreaAutoSize: () => ({ minRows: 1, maxRows: 3 }),
  defaultFormItemLayout: () => ({
    labelCol: { span: 5 },
    wrapperCol: { span: 18, offset: 1 },
  }),
  urlParse: (key) => {
    const search = queryString.parse(window.location.search);
    return key ? search[key] : search;
  },
  camelCaseUrl: (url) =>
    (url.indexOf('/') === 0 ? url.slice(1) : url)
      .split(/[_/.]/)
      .map((s, i) => (i === 0 ? s : s[0].toUpperCase() + s.slice(1)))
      .join(''),
  dateFormat: (date) => (!date ? '--' : moment(date).format('YYYY-MM-DD HH:mm:ss')),
  enumListToMap(enumList = [], { isSimple = true, key = 'key' } = {}) {
    const enumMap = {};
    enumList.forEach((i) => {
      enumMap[i[key]] = isSimple ? i.name : i;
    });
    return enumMap;
  },
  trim: (value) => (typeof value === 'string' ? value.trim() : value),
  fixNull: (val) => (val === null ? undefined : val),
  fixDivision: (a, b = 1, Precision = 2) => {
    if (b) {
      return (a / b || 0).toFixed(Precision);
    }
    return 0;
  },
  fixTableData: ({ records, current, total, size: pageSize }) => ({
    tableData: records.map((i, idx) => ({ ...i, key: (i.id || idx).toString() })),
    pagination: { pageIndex: current, pageSize },
    tablePager: { current, pageSize, total },
  }),
  jumpLogin({ noRu = false } = {}) {
    if (window.location.href.indexOf(utils.fixSysPageUrl('/login')) === -1) {
      const ru = window.decodeURIComponent(utils.urlParse('ru') || '');
      // 已有ru回跳不处理
      const jumpUrl = noRu ? '/login' : `/login?ru=${ru || window.encodeURIComponent(window.location.href)}`;
      window.location.replace(utils.fixSysPageUrl(jumpUrl));
    }
  },
  // 登录成功回跳
  loginSuccessJump(ruParamName = 'ru') {
    // 非aitob页面跳转客户管理平台首页
    let ru = utils.fixSysPageUrl('');
    for (const i of AITOB_ORIGIN_COMMON_PART) {
      const prevUrl = window.decodeURIComponent(utils.urlParse(ruParamName));
      if (prevUrl.indexOf(i) > -1) {
        // 登录成功后，返回原来的页面
        ru = prevUrl;
        break;
      }
    }

    window.location.replace(ru);
  },
  createObjectURL(obj) {
    let getUrl = null;
    if (window.createObjectURL !== undefined) {
      // basic
      getUrl = window.createObjectURL(obj);
    } else if (window.URL !== undefined) {
      // mozilla(firefox)
      getUrl = window.URL.createObjectURL(obj);
    } else if (window.webkitURL !== undefined) {
      // webkit or chrome
      getUrl = window.webkitURL.createObjectURL(obj);
    }
    return getUrl;
  },
  // file转blob
  fileToBlob(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (e) => {
        const blob = new Blob([e.target.result]);
        resolve(blob);
      };
    });
  },
  download: (data, name, type) => {
    const blob = new Blob([data], { type });
    const audioSrc = window.URL.createObjectURL(blob); // 将blob转换为音频地址
    const a = document.createElement('a');
    a.href = audioSrc;
    a.download = name; // 这里填保存成的文件名
    a.click();
    URL.revokeObjectURL(a.href);
    a.remove();
  },
  fixSysPageUrl: (url) => `${process.env.PUBLIC_URL}${url}`,
};
export default utils;
