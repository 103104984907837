const apiMap = {
  /* 公用接口 */
  apiLogin: '/web/customer/login',
  apiLoginUnion: '/web/customer/sso-login', // 登录请求_联登
  apiLogout: '/web/customer/logout',

  apiLoginMountFlagStatus: '/web/customer/security/verification-code/get-show-flag', // 登录页面打开时，获取是否需要展示验证码
  apiLoginImageCode: '/web/customer/security/verification-code/get-image-code', // 获取图片验证码

  apiLoginStatus: '/web/customer/login/simple',
  apiUpdateSelfInfo: '/web/customer/user/update-self-info', // 修改个人信息
  apiGetProductMenu: '/web/customer/auth/get-by-product', // 按照产品模块查询用户分配菜单
  apiGetResourceByType: '/web/customer/resource/get-by-type', // 查询当前商户已分配的某一类型资源
  apiGetResourceByTypes: '/web/customer/resource/get-by-types', // 查询当前商户已分配的多个类型资源
  apiGetAllResourceByType: '/web/customer/resource/get-all-by-type', // 查询某一类型全部资源
  apiGetUserProduct: '/web/customer/product/get-user-product', // 查询用户产品模块列表

  /* ------ 申请体验 ------ */
  // apiExperienceApply: '/web/customer/experience/apply', // - 未使用
  // apiExperienceList: '/web/customer/experience/list', // - 未使用

  /* ------ 日志 ------ */
  apiLogList: '/web/customer/log/list', // 数据查询

  /* ------ 商户 ------ */
  apiTenantList: '/web/customer/tenant/list', // 查询商户列表
  apiTenantAdd: '/web/customer/tenant/add', // 添加商户
  apiTenantUpdate: '/web/customer/tenant/update', // 更新商户
  // apiTenantDelete: '/web/customer/tenant/delete', // 商户删除 - 未使用
  apiTenantAll: '/web/customer/tenant/all', // 获取全部商户
  apiTenantStatusAll: '/web/customer/tenant/status/all', // 获取全部商户状态标志位
  apiTenantEnable: '/web/customer/tenant/enable', // 获取全部商户状态标志位
  apiGetTenantAllocatableMenu: '/web/customer/auth/get-tenant-allocatable-menu', // 查询商户可分配权限
  apiGetTenantMenu: '/web/customer/auth/get-tenant-menu', // 查询商户已分配菜单
  apiSaveTenantMenu: '/web/customer/auth/save-tenant-menu', // 保存商户分配的菜单
  apiTenantExport: '/web/customer/auth/export-tenant', // 商户管理导出商户配置

  /* ------ 用户 ------ */
  apiGetUserList: '/web/customer/user/list', // 查询用户列表
  apiAddUser: '/web/customer/user/add', // 添加用户
  apiUpdateUser: '/web/customer/user/update', // 更新用户
  apiDeleteUser: '/web/customer/user/delete', // 用户删除
  apiGetAllUser: '/web/customer/user/all', // 获取指定商户下的全部用户
  // apiGetUserTenantid: '/web/customer/user/tenantid', // 获取指定用户的商户id - 未使用
  apiGetUserRoles: '/web/customer/user/get-binded-role', // 用户角色列表
  apiUpdateUserRoles: '/web/customer/user/bind-role', // 修改用户的角色

  /* ------ 角色 ------ */
  apiGetRoleList: '/web/customer/role/get-role-page', // 查询角色列表
  apiGetAllRole: '/web/customer/role/get-role-list', // 查询所有角色
  apiAddRole: '/web/customer/role/create-role', // 角色添加
  apiUpdateRole: '/web/customer/role/update-role', // 角色修改
  apiDeleteRole: '/web/customer/role/delete-role', // 角色删除
  // apiGetRoleDetail: '/web/customer/role/get-role', // 角色详情 - 未使用
  apiGetRoleUsers: '/web/customer/role/get-binded-user', // 查询角色已绑定用户
  apiUpdateRoleUsers: '/web/customer/role/bind-user', // 保存角色绑定用户
  apiGetRoleAllocatableMenu: '/web/customer/auth/get-role-allocatable-menu', // 查询角色可分配权限
  apiGetRoleMenu: '/web/customer/auth/get-role-menu', // 查询角色已分配菜单
  apiSaveRoleMenu: '/web/customer/auth/save-role-menu', // 保存角色分配的菜单

  /* ------ 资源分配 ------ */
  apiGetAllocableResource: '/web/customer/broadcast-resource/get-allocatable-resource', // 查询全部可分配资源全集
  apiGetTenantResource: '/web/customer/broadcast-resource/get-tenant-resource', // 查询商户已分配资源
  apiSaveTenantResource: '/web/customer/broadcast-resource/save-tenant-resource', // 保存商户分配的资源
  apiGetAllResourceTypes: '/web/customer/resource/get-resource-types', // 获取所有资源类型

  /* ------ 产品模块 ------ */
  apiAddProduct: '/web/customer/product/create-product', // 新增产品模块
  apiUpdateProduct: '/web/customer/product/update-product', // 编辑产品模块
  apiGetAllProduct: '/web/customer/product/get-all-product', // 获取所有产品模块

  /* ------ 菜单管理 ------ */
  apiGetAllMenu: '/web/customer/auth/get-all-menu', // 查询所有菜单
  apiGetUserMenus: '/web/customer/auth/get-user-menu', // 查询当前用户已被分配的菜单
  apiAddFristMenu: '/web/customer/first-menu/create-first-menu', // 新增一级菜单
  apiUpdateFristMenu: '/web/customer/first-menu/update-first-menu', // 编辑一级菜单
  apiGetFristMenu: '/web/customer/first-menu/get-by-product', // 获取某产品下的所有一级菜单
  // apiGetFristMenuDetail: '/web/customer/first-menu/get-first-menu', // 获取一级菜单详情 - 未使用
  apiAddSecondMenu: '/web/customer/second-menu/create-second-menu', // 新增二级菜单
  apiUpdateSecondMenu: '/web/customer/second-menu/update-second-menu', // 编辑二级菜单
  apiGetSecondMenu: '/web/customer/second-menu/get-by-first-menu', // 获取某一级菜单下的二级菜单列表
  // apiGetSecondMenuDetail: '/web/customer/second-menu/get-second-menu', // 获取二级菜单详情 - 未使用
  apiAddFunctionPoint: '/web/customer/function-point/create-function-point', // 新增功能点
  apiUpdateFunctionPoint: '/web/customer/function-point/update-function-point', // 编辑功能点
  apiDeleteFunctionPoint: '/web/customer/function-point/delete-function-point', // 删除功能点
  apiFunctionPointBindDetail: '/web/customer/function-point/get-bind-tenant', // 查看功能点绑定详情
  // apiGetFunctionPoint: '/web/customer/function-point/get-function-point', // 查询功能点详情 - 未使用
  apiImportMenu: '/web/customer/auth/import-first-menus', // 菜单导入
  apiExportMenu: '/web/customer/auth/download-first-menus', // 菜单导出

  /* ------ 产品接口管理 ------ */
  apiProductUrlCreate: '/web/customer/url/create', // 新增接口
  apiProductUrlUpdate: '/web/customer/url/update', // 更新接口
  apiProductUrlDelete: '/web/customer/url/delete', // 删除接口
  apiProductUrlDetail: '/web/customer/url/detail', // 查看接口详情
  apiProductUrlPageList: '/web/customer/url/get-page-list', // 获取接口分页列表
  apiProductUrlAll: '/web/customer/url/get-all', // 获取所有接口-不分页
  apiProductUrlBindFunctionPoints: '/web/customer/url/get-bind-function-point', // 获取接口绑定的功能点

  /* ------ 数字人演示 ------ */
  apiDigitalTextDriven: '/web/sphinx/digital/text-driven', // 文本驱动
  apiDigitalVoiceDriven: '/web/sphinx/digital/voice-driven', // 语音驱动
  apiDigitalVoiceAudition: '/web/sphinx/digital/voice-audition', // 试听
  apiPolyphonePinin: '/web/sphinx/polyphone/pinyin', // 多音字检测

  /* ------ 小程序管理 ------ */
  apiPocList: '/web/config/poc/poc-list', // poc列表查询
  apiPocDetail: '/web/config/poc/poc-detail', // poc详情
  apiPocAdd: '/web/config/poc/poc-add', // 新增poc
  apiPocUpdate: '/web/config/poc/poc-update', // 修改poc
  apiPocDelete: '/web/config/poc/poc-delete', // 删除poc
  apiPocOnline: '/web/config/poc/poc-online', // 上线poc
  apiPocOffline: '/web/config/poc/poc-offline', // 下线poc

  /* ------ 云端接口管理 ------ */
  apiInterfaceList: '/web/customer/interface-management/get-page-list', // 接口权限列表
  apiInterfaceAnchorResources: '/web/customer/resource/get-interface-resource', // 获取已生成key的配置资源-根据ID
  apiInterfaceCreateInteractive: '/web/customer/interface-management/create', // 接口权限新增-交互
  apiInterfaceCreateBroadcast: '/web/customer/interface-management/broadcast/create', // 接口权限新增-播报
  apiInterfaceUpdateInteractive: '/web/customer/interface-management/update', // 接口权限修改-交互
  apiInterfaceUpdateBroadcast: '/web/customer/interface-management/broadcast/update', // 接口权限修改-播报
  apiInterfaceGetDetailInteractive: '/web/customer/interface-management/get-detail', // 接口权限详情-交互
  apiInterfaceGetDetailBroadcast: '/web/customer/interface-management/broadcast/get-detail', // 接口权限详情-播报
  apiInterfaceGetSecret: '/web/customer/interface-management/get-secret', // 查看秘钥
  apiInterfaceGetParametersInteractive: '/web/customer/interface-management/get-parameters', // 查看参数 - 交互
  apiInterfaceGetParametersBroadcast: '/web/customer/interface-management/broadcast/get-parameters', // 查看参数 - 播报
  apiInterfaceGetDetailByKey: '/web/customer/interface-management/get-detail-by-key', // 根据AccessKey获取接口权限详情
  apiInterfaceRecordList: '/web/customer/interface-record/statistics/get-page-list', // 查询接口统计列表（分页）
  apiInterfaceRecordDetail: '/web/customer/interface-record/statistics/get-detail', // 查询接口统计详情
  apiInterfaceRecordStreamList: '/web/customer/interface-record/statistics/get-realtime-stream-page-list', // 接口实时监控（分页）
  apiAicenterPlayOnlineList: '/web/smart/aicenter/play/online-play-create/list', // 接口权限配置 - 获取上线剧本
  apiAicenterPlayList: '/web/smart/aicenter/play/page-list-create', // 接口权限配置 - 获取剧本
  apiInterfaceExport: '/web/customer/broadcast-resource/export-interface', // 播报接口管理导出播报接口配置

  /* --------------  云端接口管理-播报-apaas4.0  ------------- */
  apiInterfaceBroadcastApaasList: '/web/customer/broadcast-interface-management/get-page-list', // 接口列表
  apiInterfaceBroadcastApaasBindedAnchorResources: '/web/customer/broadcast-resource/get-interface-resource', // 获取接口配置的主播资源配置列表
  apiInterfaceBroadcastApaasCreateAndUpdate: '/web/customer/broadcast-interface-management/create', // 新增/更新接口
  apiInterfaceBroadcastApaasAnchorResources: '/web/customer/broadcast-resource/get-all-by-type', // 可配置的主播资源配置列表
  apiInterfaceBroadcastApaasDetail: '/web/customer/broadcast-interface-management/get-detail', // 接口详情

  /* ------ trtc ------ */
  apiScenarioOperatorAllReadyRooms: '/web/scenario/operator/all-ready-rooms', // 获取转人工房间的人工客服信息
  apiScenarioOperatorJoinRooms: '/web/scenario/operator/join-room', // 人工客服加入指定房间
  apiScenarioOperatorExitRooms: '/web/scenario/operator/exit-room', // 人工客服退出指定房间

  /* ------ smart中台接口 ------ */
  apiAicenterPlayOnlineAllList: '/web/smart/aicenter/play/online-play/list', // poc配置 - 获取全量上线剧本
  apiAicenterGetPlayscene: '/web/smart/aicenter/play/playscene/list', // 对话管理 - 获取剧本场景
};

export default apiMap;
