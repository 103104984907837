import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Row, Col, Form, Space } from 'antd';

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: this.props.initialValues,
    };
    this.formRef = React.createRef();
  }

  handleSearch = (e) => {
    e.preventDefault();
    this.formRef.current.validateFields().then((values) => {
      this.props.onSearch(values);
    });
  };

  handleReset = () => {
    this.formRef.current.resetFields();
  };

  render() {
    const { initialValues } = this.state;
    const { formItems = [], showRest, buttonsSpan, onValuesChange, extra } = this.props;

    return (
      <Form
        ref={this.formRef}
        style={{ width: '100%', margin: '16px 0 32px 0' }}
        initialValues={initialValues}
        layout="inline"
        onValuesChange={onValuesChange.bind(this)}
      >
        <Row gutter={24} style={{ width: '100%' }}>
          {formItems.map(({ key, label, rules, normalize, formItem, span, valuePropName }) => (
            <Col span={span || 6} key={key}>
              <Form.Item
                label={label}
                name={key}
                rules={rules}
                normalize={normalize}
                style={{ width: '100%' }}
                valuePropName={valuePropName}
              >
                {formItem}
              </Form.Item>
            </Col>
          ))}
          <Col span={buttonsSpan}>
            <Space justify="start" size="small">
              <Button type="primary" onClick={this.handleSearch}>
                查询
              </Button>
              {showRest && <Button onClick={this.handleReset}>重置</Button>}
              {extra}
            </Space>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default Search;

Search.propTypes = {
  formItems: PropTypes.array,
  onSearch: PropTypes.func,
  initialValues: PropTypes.object,
  showRest: PropTypes.bool,
};

Search.defaultProps = {
  formItems: [],
  onSearch: () => {},
  initialValues: {},
  showRest: false,
  buttonsSpan: 6, // 按钮集合的占位空间,
  onValuesChange: () => {},
  extra: null,
};
