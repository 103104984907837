import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Table } from 'antd';

import utils from '../../../utils';

class WrapperTable extends Component {
  static propTypes = {
    tablePager: PropTypes.object,
    showIndex: PropTypes.bool,
    onTableChange: PropTypes.func,
  };

  static defaultProps = {
    tablePager: {},
    showIndex: false,
    onTableChange: () => {},
  };

  handleTableChange = ({ current: pageIndex, pageSize }, filters, sorter) => {
    this.props.onTableChange({ pageIndex, pageSize }, sorter, filters);
  };

  render() {
    const { tablePager, showIndex, columns, rowKeyName = 'id', ...rest } = this.props;

    const { current = 1, pageSize = 0 } = tablePager;
    const prefixIndex = (current - 1) * pageSize;

    const tableColumns = columns;
    if (showIndex) {
      tableColumns.unshift({
        title: '序号',
        dataIndex: 'row-index',
        width: 70,
        align: 'center',
        render: (text, item, idx) => prefixIndex + idx + 1,
      });
    }

    return (
      <Table
        rowKey={(record) => record.key || record[rowKeyName]}
        bordered
        pagination={utils.getPaginationConfig(tablePager)}
        onChange={this.handleTableChange}
        {...rest}
        columns={tableColumns}
      />
    );
  }
}

export default WrapperTable;
