import React, { Component } from 'react';
import { Space, Button, Scroll, Icon, OptionsBar, Slider, Pop, Modal, PolyphonicNode, PauseNode } from './component';
import classNames from 'classnames';
import utils from './utils';
import Utils from '../../../utils';
import './rich-text.scss';

const inputNode = { type: 'input' };
const textNode = (text) => ({
  key: Math.random(),
  type: 'text',
  text,
});
const pauseNode = (tag) => ({
  key: Math.random(),
  type: 'pause',
  text: '',
  tag,
});
const polyphonicNode = (tag, text, value) => ({
  key: Math.random(),
  type: 'polyphonic',
  tag,
  text,
  value,
});

const numberNode = (tag, text) => ({
  key: Math.random(),
  type: 'number',
  tag,
  text,
});

const wrapNode = () => ({
  key: Math.random(),
  type: 'wrap',
  text: '\n',
});

const pauseEnum = [
  {
    label: '连读',
    value: '连读',
  },
  {
    label: '0.5s',
    value: '0.5',
  },
  {
    label: '1s',
    value: '1',
  },
  {
    label: '2s',
    value: '2',
  },
  {
    label: '移除',
    value: '移除',
  },
];

const numberDefaultEnum = [
  {
    label: '数字',
    type: 'number',
  },
  {
    label: '金额',
    type: 'currency',
  },
  {
    label: '号码',
    type: 'telephone',
  },
];

const numberMap = {
  telephone: '号码',
  currency: '金额',
  number: '数字',
};

// ssml转富文本
const ssmlToRTList = (ssmlParam) => {
  let ssml = ssmlParam;
  let lableCache = '';
  let startMatch = false;
  let hasSlash = false;
  const textList = [];
  let size = 0;
  let globalSpeed = '1'; // 全局语速
  ssml = ssml.replace(/\r\n/g, '\\n');
  ssml = ssml.replace(/\n/g, '\\n');
  // 全局语速判断
  const speedRegExp = /<prosody rate="(.+?)">(.+?)<\/prosody>/g;
  if (ssml && speedRegExp.test(ssml)) {
    ssml.replace(speedRegExp, ($1, $2, $3) => {
      globalSpeed = (2 - $2).toFixed(1);
      ssml = $3;
    });
  }
  ssml = ssml.replace(/\\n/g, '\n');

  // 匹配思路：出现"<"被认为是标签开始，有过"/"再有">"认为是闭合
  // 弊端：文案中不能有>号，TODO 需要改进
  ssml.split('').forEach((item) => {
    if (item === '\n') {
      textList.push(wrapNode());
      size += 1;
      return;
    }

    if (startMatch === true) {
      lableCache += item;
      // 匹配标签结尾
      if (hasSlash && item === '>') {
        startMatch = false;
        hasSlash = false;
        const node = labelToNode(lableCache);
        if (node) {
          textList.push(node);
        }
        size += node?.text ? node.text.length : 0;

        lableCache = '';
      }
      if (item === '/') hasSlash = true;
      return;
    }
    // 匹配标签开头
    if (item === '<') {
      lableCache += item;
      startMatch = true;
      return;
    }
    textList.push(textNode(item));
    size += 1;
  });
  return [textList, size, globalSpeed];
};

// ssml标签转富文本node
const labelToNode = (label) => {
  let obj = null;
  const regPause = /<break strength="(.+?)" time="?(.+?)"\/>/g;
  const regPolyphonic = /<phoneme py="(.+?)" pinyin="(.+?)">(.+?)<\/phoneme>/g;
  const regNumber = /<say-as interpret-as="(.+?)">(.+?)<\/say-as>/;

  if (regPause.test(label)) {
    label.replace(regPause, ($1, strength, time) => {
      if (strength === 'weak') {
        obj = pauseNode('连读');
      } else {
        obj = pauseNode(time);
      }
    });
  } else if (regPolyphonic.test(label)) {
    label.replace(regPolyphonic, ($1, $2, $3, $4) => {
      obj = polyphonicNode($3, $4, $2);
    });
  } else if (regNumber.test(label)) {
    label.replace(regNumber, ($1, $2, $3) => {
      obj = numberNode($2, $3);
    });
  }
  return obj;
};

// 文字转RTList
const textToRTList = (text) => text.split('').map((item) => (item === '\n' ? wrapNode() : textNode(item)));

const RTListCheck = (textList) => {
  let prevPauseIdx = -1;
  textList.forEach((item, idx) => {
    const { type, text } = item;
    switch (type) {
      case 'pause':
        if (prevPauseIdx !== -1) textList.splice(prevPauseIdx, 1);
        prevPauseIdx = idx;
        break;
      case 'text':
        if (![' ', ' '].includes(text)) prevPauseIdx = -1;
        break;
      case 'wrap':
        break;
      default:
        prevPauseIdx = -1;
    }
  });
  return textList;
};

const selectPrevent = (e) => {
  let { target } = e;
  while (target) {
    if (target.id === 'rt-context') return;
    target = target.parentElement;
  }
  e.preventDefault();
};

class RichText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 'init',
      listenEvent: false, // 时间劫持

      textList: [],
      polyphonicEnum: [],
      numberEnum: [],

      // bar相关
      slideShow: false,
      globalSpeed: 1.0, // 全局语速
      pauseBar: {},
      polyphonicBar: {},
      numberBar: {},

      // 光标输入框相关
      inputIdx: -1, // 光标位置
      blurIdx: -1, // 光标失焦的位置,只保留200ms
      composition: false, // 输入法开启状态
      size: 0, // 字数

      // 选区相关
      selection: [0, 0, ''], // 选区[起，终, 选中文字]
      ctxMouseDown: false, // 文档鼠标按下检测
      inContext: false, // 是否在context中

      // 试听气泡
      audition: {
        show: false,
        left: 0,
        top: 0,
      },

      // 弹窗相关
      partModal: {},
      preventModal: {},
      importModal: {},
      insertConfirmModal: {},

      /* ------ 播放相关 ------ */
      playingIdx: -1,
      sentenceSection: [],

      // 插入相关
      insertInfo: {},
    };
    this.inputRef = null; // 光标ref
    this.nodeDomList = []; // 元素真实dom列表
  }

  componentDidMount() {
    const { value } = this.props;
    const [textList, size, globalSpeed] = ssmlToRTList(value);
    this.setState({ textList, size, globalSpeed });
    this.props.onRef(this);

    // 点击鼠标，记录是否在编辑器内
    document.addEventListener('mousedown', (e) => {
      let { target } = e;
      while (target) {
        if (target.id === 'rt-context') {
          this.setState({ inContext: true });
          return;
        }
        target = target.parentElement;
      }
    });

    // 移出富文本，取消选区
    document.addEventListener('mouseup', (e) => {
      if (this.state.inContext) {
        let { target } = e;
        while (target) {
          if (target.id === 'rt-context') return;
          target = target.parentElement;
        }
        document?.selection?.empty() || window?.getSelection()?.removeAllRanges();
      }
      this.setState({ inContext: false });
    });

    document.addEventListener('keydown', this.onCtxKeyDown.bind(this));
    document.addEventListener('scroll', this.onCtxScroll.bind(this));
  }

  componentWillUnmount() {
    // this.setState = (state, callback) => {
    //     return;
    // };
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    const { playingIdx, status, value, listenEvent } = nextProps;
    if (prevState.playingIdx !== playingIdx) {
      return { playingIdx };
    }

    if (prevState.status === 'loading' && status === 'success') {
      const [textList, size, globalSpeed] = ssmlToRTList(value);
      const newTextList = RTListCheck(textList);
      return { textList: newTextList, size, globalSpeed, status };
    }
    if (prevState.status !== status) {
      return { status };
    }

    if (prevState.listenEvent === true && listenEvent === false) {
      removeEventListener('selectstart', selectPrevent);
      return { listenEvent };
    }
    if (prevState.listenEvent === false && listenEvent === true) {
      addEventListener('selectstart', selectPrevent);
      return { listenEvent };
    }
    return null;
  };

  /* ------ 通用方法 ------ */
  // ctx滚动
  onCtxScroll() {
    // TODO 需添加节流
    this.onPopHide(false);
  }

  setStateSync(data) {
    return new Promise((resolve) => this.setState(data, resolve));
  }

  /* ------ 数据转换 ------ */

  // 富文本转ssml
  RTListTossml(RTList, hasSpeed = true /* 是否需要speed */) {
    const { globalSpeed } = this.state;
    let ssml = '';
    RTList.forEach((item) => {
      const { type, text, tag, value } = item;
      switch (type) {
        case 'text':
          ssml += text;
          break;
        case 'pause':
          if (tag === '连读') {
            ssml += '<break strength="weak" time=""/>';
          } else {
            ssml += `<break strength="strong" time="${tag}"/>`;
          }
          break;
        case 'polyphonic':
          ssml += `<phoneme py="${value}" pinyin="${tag}">${text}</phoneme>`;
          break;
        case 'number':
          ssml += `<say-as interpret-as="${tag}">${text}</say-as>`;
          break;
        case 'wrap':
          ssml += '\n';
          break;
        default:
          return;
      }
    });
    if (hasSpeed) {
      ssml = `<prosody rate="${(2 - globalSpeed).toFixed(1)}">${ssml}</prosody>`;
    }
    return ssml;
  }

  /* ------ 头部按钮 ------ */

  // 多音字检测
  async globalPolyphonicCheck() {
    const { textList } = this.state;
    const polyphonicMap = {};
    let testText = '';
    textList.forEach(({ text, type }, idx) => {
      const result = type === 'text';
      if (result) {
        polyphonicMap[text] = [...(polyphonicMap[text] ? polyphonicMap[text] : []), idx];
        testText += text;
      }
      return result;
    });

    // 从父元素获取拼音map
    const pinyin = await this.props.getPolyphonic(testText);
    Object.keys(pinyin).forEach((word) => {
      const { pinyinsDefault, pinyinsNum, recommendDefault, recommendNum } = pinyin[word];
      polyphonicMap[word].forEach((textListIdx, idx) => {
        const tag = recommendDefault ? recommendDefault[idx] : pinyinsDefault[0];
        const value = recommendNum ? recommendNum[idx] : pinyinsNum[0];
        textList[textListIdx] = polyphonicNode(tag, word, value);
      });
    });
    this.setState({ textList });
    this.props.onChange(this.RTListTossml(textList));
    this.onAuditionHandle('stop', true);
  }

  // 滑动弹窗控制
  sliderHandle(slideShow, e) {
    e.stopPropagation();
    this.setState({ slideShow });
    if (slideShow) {
      window.onmousedown = () => {
        this.sliderHandle(false, e);
        window.onmousedown = null;
      };
    }
  }

  // 全局速度更改
  onSpeedChange(globalSpeed) {
    const { textList } = this.state;
    this.setState({ globalSpeed }, () => {
      this.props.onChange(this.RTListTossml(textList));
    });
    this.onAuditionHandle('stop', true);
  }

  // 导入弹窗
  importModalHandle(show, inputIdx) {
    const { textList } = this.state;
    if (show) {
      const insertIdx = inputIdx > -1 ? inputIdx : textList.length;
      this.setState({ insertInfo: { insertIdx } });
      this.onAuditionHandle('stop', true);
    }
    this.setState({ importModal: { show } });
  }

  /* ------ 选区相关 ------ */
  // 获取选区idx
  getSelection() {
    const selection = window.getSelection();
    const selectionText = selection.toString();
    const { anchorNode } = selection;
    if (!anchorNode) return this.state.selection;

    let {
      startContainer: {
        parentElement: startElement, // 选中的第一个元素
      },
      endContainer: {
        parentElement: endElement, // 选中的最后一个元素
      },
    } = selection.getRangeAt(0);
    if (startElement.className.indexOf('rt-node') < 0) startElement = startElement.parentElement;
    if (endElement.className.indexOf('rt-node') < 0) endElement = endElement.parentElement;
    const startIdx = parseInt(startElement.getAttribute('idx'), 10);
    const endIdx = parseInt(endElement.getAttribute('idx'), 10) + 1;
    return [startIdx, endIdx, selectionText];
  }

  // 取消选区
  cancelSelection() {
    const ctxSelection = window.getSelection();
    ctxSelection.removeAllRanges();
  }

  // 全选
  selectAll() {
    const context = document.getElementById('rt-context');
    const range = document.createRange();
    range.selectNodeContents(context);
    const ctxSelection = window.getSelection();
    ctxSelection.removeAllRanges();
    ctxSelection.addRange(range);

    const selection = [0, this.state.textList.length, ctxSelection.toString()];
    this.setState({ selection });
    this.onInputBlur();

    // 弹出试听气泡
    // this.auditionPopShow();
    this.onAuditionHandle('standby');
  }

  // 选择结束
  onSelectEnd(e) {
    e.stopPropagation();
    const selection = this.getSelection();
    this.setState({ selection, blurIdx: -1 }, () => this.selectHandle(e));
  }

  selectHandle(e) {
    const { textList, selection } = this.state;
    const [startIdx, endIdx, selectionText] = selection;
    e.target = this.nodeDomList[startIdx]; // mouseUp时，可能是后一个元素的前一半位置，所以设置指针指向初始元素

    // 选中单个字设置多音字
    if (selectionText.length === 1) {
      if (textList[startIdx].type === 'text') {
        const textRegExp = /[\u4e00-\u9fa5]/;
        if (!textRegExp.test(textList[startIdx].text)) return;
        this.polyphonicNodeClick(startIdx, true, e);
      }
    } else {
      // 是数字，设置数字读音
      if (endIdx - startIdx <= 1) return;
      if (selectionText === parseFloat(selectionText)) {
        this.numberNodeClick({}, e);
      }
    }
    // 弹出试听气泡
    // this.auditionPopShow();
  }

  // 复制
  onCtxCopy(e) {
    e.preventDefault();
    const { textList, selection } = this.state;
    const [startIdx, endIdx] = selection;
    const clipboard = textList.slice(startIdx, endIdx);
    e.clipboardData.setData('text', this.RTListTossml(clipboard, false));
  }

  // 剪切
  async onCtxCut(e) {
    e.preventDefault();
    const { textList, selection } = this.state;
    let { size } = this.state;
    const [startIdx, endIdx] = selection;
    const clipboard = textList.splice(startIdx, endIdx - startIdx, inputNode);

    clipboard.forEach(({ text }) => (size -= text.length));

    await this.setStateSync({ inputIdx: startIdx, textList, size });
    this.inputRef.focus();

    e.clipboardData.setData('text', this.RTListTossml(clipboard, false));
    this.onPopHide(false);
    this.onAuditionHandle('stop', true);
  }

  // 选区粘贴
  onCtxPaste(e) {
    e.preventDefault(); // 阻止粘贴
    const { selection, inputIdx } = this.state;
    if (inputIdx > -1) return; // 见 onInputPaste()
    const clipboardText = e.clipboardData.getData('text');
    const [startIdx, endIdx] = selection;
    // 无选区 取消
    if (startIdx === 0 && endIdx === 0) return;

    const [clipboard] = ssmlToRTList(clipboardText);
    this.insertText({ type: '粘贴', insertRange: selection, insertList: clipboard });
    this.onPopHide(false);
    this.onAuditionHandle('stop', true);
  }

  // 文档keyDown
  async onCtxKeyDown(e) {
    const { keyCode, ctrlKey, metaKey } = e;
    const { textList, listenEvent, selection, inputIdx } = this.state;
    let { size } = this.state;
    // 有光标 return
    if (!listenEvent || inputIdx > -1) return;
    const [startIdx, endIdx, selectionText] = selection;
    switch (keyCode) {
      // 上
      case 38:
        this.cursorEnjambment('up');
        break;
      // 下
      case 40:
        this.cursorEnjambment('down');
        break;
      // 左
      case 37:
        this.cancelSelection();
        this.cursorMove(startIdx, true);
        break;
      // 右
      case 39:
        this.cancelSelection();
        this.cursorMove(endIdx, true);
        break;
      // delete backspace
      case 8:
      case 46: {
        if (!selectionText) return;
        const delList = textList.splice(startIdx, endIdx - startIdx, inputNode);
        delList.forEach(({ text = '' }) => (size -= text.length));

        await this.setStateSync({ inputIdx: startIdx, textList, size });
        this.inputRef.focus();

        this.onPopHide();
        this.props.onChange(this.RTListTossml(textList));
        this.onAuditionHandle('stop', true);
        break;
      }
      // ctrl+A || command+A 全选
      case 65:
        if (ctrlKey || metaKey) this.selectAll();
        break;
      default:
        return;
    }
  }

  /* ------ 光标相关 ------ */

  // 光标插入
  async cursorInsert(idx, e) {
    e.stopPropagation();
    const [, , selectionText] = this.getSelection();
    if (selectionText.length > 0) return;
    const { clientX, target } = e;
    const { left, width } = target.getBoundingClientRect();
    const position = clientX - left < width / 2 ? 0 : 1;
    const inputIdx = idx + position;

    const { textList } = this.state;
    textList.splice(inputIdx, 0, inputNode);
    await this.setStateSync({ inputIdx, textList });
    this.inputRef.focus();
    this.onAuditionHandle('stop', true);
  }

  // 在空白处按下文档末尾展示光标（直接用onClick会选区选取文字时，会误触）
  onCtxMouseDown(e) {
    // 不能使用e.stopPropagation,否则会阻碍window.onmousedown的劫持
    if (e.target.id !== 'rt-context') return;
    // 选区设置
    this.setState({ ctxMouseDown: true });

    // 抬起后自动将ctxMouseDown置为false，以供下次判断使用
    window.onmouseup = () => {
      this.setState({ ctxMouseDown: false });
      window.onmouseup = null;
    };
  }

  async onCtxMouseUp(e) {
    const { textList, ctxMouseDown } = this.state;
    // 判断mouseDown是不是在背景，是的话才会设置光标
    if (ctxMouseDown) {
      const { nodeDomList } = this;
      const domLen = nodeDomList.length; // TODO 可优化？
      let inputIdx = textList.length;
      // 光标插入到折行文本末尾
      for (let i = 0; i < domLen; i++) {
        const node = nodeDomList[i];
        if (node) {
          const { top } = node.getBoundingClientRect();
          if (top > e.clientY) {
            inputIdx = i - 1;
            break;
          }
        }
      }
      textList.splice(inputIdx, 0, inputNode);
      await this.setStateSync({ inputIdx, textList });
      this.inputRef.focus();
    } else {
      const selection = this.getSelection();
      this.setState({ selection }, () => this.selectHandle(e));
    }
  }

  // 输入按键
  onCursorKeyDown(e) {
    const { textList } = this.state;
    let { inputIdx, size } = this.state;
    const { keyCode, shiftKey, ctrlKey, metaKey } = e;
    const textListLen = textList.length;
    if (shiftKey) {
      // TODO
    } else {
      switch (keyCode) {
        // 删除 backspae
        case 8: {
          e.stopPropagation();
          if (inputIdx <= 0) return;
          const backspaceNode = textList.splice(inputIdx - 1, 1)[0];
          const { type, text } = backspaceNode;
          if (type === 'polyphonic') {
            textList.splice(inputIdx - 1, 0, textNode(text));
          } else if (type === 'number') {
            const numberList = text.split('').map((item) => textNode(item));
            textList.splice(inputIdx - 1, 0, ...numberList);
            inputIdx += numberList.length;
          } else {
            if (text) size -= text.length;
            inputIdx -= 1;
          }
          this.setState({ inputIdx, textList, size });
          this.props.onChange(this.RTListTossml(textList));
          break;
        }
        // 删除 delete
        case 46: {
          e.stopPropagation();
          const deleteText = textList.splice(inputIdx + 1, 1)[0]?.text;
          if (deleteText) size -= deleteText.length;
          this.setState({ inputIdx, textList, size });
          this.props.onChange(this.RTListTossml(textList));
          break;
        }
        // home
        case 36:
          this.cursorMove(0, false);
          break;
        // end
        case 35:
          this.cursorMove(textListLen - 1, false);
          break;
        // 左
        case 37:
          if (inputIdx - 1 < 0) return; // 最头部限制
          this.cursorMove(inputIdx - 1, false);
          break;
        // 右
        case 39:
          if (inputIdx + 1 >= textListLen) return; // 最尾部限制
          this.cursorMove(inputIdx + 1, false);
          break;
        // 上
        case 38:
          this.cursorEnjambment('up');
          return; // 不是break break执行两次设置textList，指针不正常
        // 下
        case 40:
          this.cursorEnjambment('down');
          return;
        case 65:
          if (ctrlKey || metaKey) this.selectAll();
          break;
        default:
          return;
      }
    }
  }

  // 移动光标
  async cursorMove(idx, focus) {
    const { textList, inputIdx } = this.state;
    if (inputIdx > -1) textList.splice(inputIdx, 1); // 光标先删后加
    textList.splice(idx, 0, inputNode);
    await this.setStateSync({ inputIdx: idx, textList });
    if (focus) this.inputRef.focus();
  }

  // 光标跨行移动
  async cursorEnjambment(direction) {
    const { inputIdx, textList, selection } = this.state;
    let hasResult = false;
    const { nodeDomList } = this;

    let startIdx = -1;
    let inputNode = null;
    if (inputIdx > -1) {
      startIdx = inputIdx + 1;
      inputNode = this.inputRef;
    } else {
      const [startIdx] = selection;
      inputNode = nodeDomList[startIdx + 1];
    }
    const { left: cursorLeft, top: cursorTop, bottom: cursorBottom } = inputNode.getBoundingClientRect();
    if (direction === 'up') {
      for (let i = startIdx; i >= 0; i--) {
        const nodeDom = nodeDomList[i];
        if (!nodeDom) continue;
        const { left, top, width, height } = nodeDom.getBoundingClientRect();
        if (top < cursorTop - height / 2 && left - width / 2 <= cursorLeft) {
          this.cursorMove(i, true);
          hasResult = true;
          break;
        }
      }
      // 第一行按↑，直接跳到起始位置
      if (!hasResult) this.cursorMove(0, true);
    } else if (direction === 'down') {
      for (let i = startIdx, len = nodeDomList.length; i < len; i++) {
        const item = nodeDomList[i];
        if (!item) continue;
        const { top, right, width, height } = item.getBoundingClientRect();
        if (top >= cursorTop + height / 2) {
          // <br>直接跳
          if (item.tagName === 'BR') {
            this.cursorMove(i - 1, true);
            hasResult = true;
            break;
          } else if (right + width / 2 >= cursorLeft) {
            this.cursorMove(i, true);
            hasResult = true;
            break;
          }
        }
      }
      // 倒数第二行，移到最后
      if (!hasResult) this.cursorMove(textList.length - 1, true);

      // 超过底边 向上滚3行
      const inner = document.getElementById('rt-scroll-inner');
      const scroll = document.getElementById('rt-scroll');
      const { bottom: innerBottom } = scroll.getBoundingClientRect();
      if (cursorBottom > innerBottom - 28) inner.scrollTop += 84;
    }
  }

  async focusCursor() {
    await this.setStateSync({ inputIdx: 0, textList: [inputNode] });
    this.inputRef.focus();
  }

  /* ------ 输入相关 ------- */

  // input更改
  onInputChange(e) {
    e.stopPropagation();
    const text = e.target.innerText.replace(/\n\n/g, '\n');
    const { composition, inputIdx, textList, size } = this.state;

    const nodeList = textToRTList(text);
    const nodeListLen = nodeList.length;

    if (composition === false) {
      // 禁止输入
      if (size >= this.props.maxSize) {
        this.modalHandle('preventModal', true, '输入');
        this.inputRef.blur();
        return;
      }
      textList.splice(inputIdx, 0, ...nodeList);
      this.setState({
        textList,
        inputIdx: inputIdx + nodeListLen,
        size: size + nodeListLen,
      });
      this.props.onChange(this.RTListTossml(textList));
      this.onAuditionHandle('stop', true);
      this.inputRef.innerText = '';
    }
  }

  // input 失焦
  async onInputBlur() {
    const { inputIdx } = this.state;
    let { textList } = this.state;
    textList = textList.filter(({ type }) => type !== 'input'); // TODO 可优化直接删除inputIdx,粘贴超长时，删的不准
    this.props.onChange(this.RTListTossml(textList));
    await this.setStateSync({
      textList,
      inputIdx: -1,
      blurIdx: inputIdx,
    });
    setTimeout(() => this.setState({ blurIdx: -1 }), 200);
  }

  // 输入法开始
  onInputCompositionStart() {
    this.setState({ composition: true });
  }

  // 输入法结束
  onInputCompositionEnd(e) {
    const { inputIdx } = this.state;
    const text = e.target.innerText;
    this.setState({ composition: false });
    const insertList = text.split('').map((item) => textNode(item));
    this.insertText({ type: '输入', insertIdx: inputIdx, insertList });

    this.inputRef.innerText = '';
  }

  // input中粘贴
  onInputPaste(e) {
    e.preventDefault(); // 阻止粘贴
    const { inputIdx } = this.state;
    const clipboardText = e.clipboardData.getData('text');
    const [clipboard] = ssmlToRTList(clipboardText);
    this.insertText({ type: '粘贴', insertIdx: inputIdx, insertList: clipboard });
  }

  /* ------ tag点击相关 ------- */

  // 暂停节点点击
  pauseNodeClick(idx, e) {
    e.stopPropagation();
    const { width, height, left, top } = e.target.getBoundingClientRect();
    const { textList } = this.state;
    const { tag } = textList[idx];

    const pauseBar = {
      show: true,
      left: left - (52 - width) / 2,
      top: top + height + 7,
      value: tag,
      idx,
    };

    this.setState({ pauseBar }, () => this.onPopHide(true));
  }

  // 多音字节点点击
  async polyphonicNodeClick(idx, isSelection, e) {
    e.stopPropagation();
    let { target } = e;
    // 父元素中心
    if (['re-polyphonic-text', 'rt-tag'].includes(target.className)) {
      target = target.parentNode;
    }
    const { width, height, left, top } = target.getBoundingClientRect();
    const { textList } = this.state;
    const { text, tag } = textList[idx];
    const polyphonic = await this.props.getPolyphonic(text); // 获取读音
    const polyphonicList = polyphonic[text]?.pinyinsDefault;
    const pinyinsNum = polyphonic[text]?.pinyinsNum;
    if (!polyphonicList) return;

    const polyphonicEnum = [
      ...polyphonicList.map((label, idx) => ({
        label,
        value: pinyinsNum[idx],
      })),
    ];
    if (!isSelection) {
      polyphonicEnum.push({
        label: '移除',
        value: '移除',
      });
    }

    const polyphonicBar = {
      show: true,
      left: left - (52 - width) / 2,
      top: top + height + 7,
      value: tag,
      idx,
    };

    this.setState({ polyphonicBar, polyphonicEnum }, () => this.onPopHide(true));
  }

  // 数字节点
  numberNodeClick({ idx, tag }, e) {
    e.stopPropagation();
    // 修改
    if (idx > -1) {
      let { target } = e;
      // 父元素中心
      if (['re-polyphonic-text', 'rt-tag'].includes(target.className)) target = target.parentNode;

      const { width, height, left, top } = target.getBoundingClientRect();
      const numberEnum = [...numberDefaultEnum];
      numberEnum.push({
        label: '移除',
        type: 'remove',
      });

      const numberBar = {
        show: true,
        left: left - (52 - width) / 2,
        top: top + height + 7,
        value: tag,
        idx,
        type: 'update',
      };
      this.setState({ numberBar, numberEnum }, () => this.onPopHide(true));
    } else {
      this.numberAdd();
    }
  }

  numberAdd() {
    const [startIdx, endIdx] = this.getSelection();
    const nodeDomList = this.nodeDomList.slice(startIdx, endIdx);
    let rangeRight = 0;
    if (nodeDomList.length === 0) return;
    const { top: rangeTop, left: rangeLeft } = nodeDomList[0].getBoundingClientRect();
    nodeDomList.forEach((item) => {
      if (!item) return;
      const { top, right } = item.getBoundingClientRect();
      if (top === rangeTop && right > rangeRight) rangeRight = right;
    });
    const numberBar = {
      show: true,
      left: rangeLeft + (rangeRight - rangeLeft) / 2 - 25,
      top: rangeTop + 35,
      type: 'new',
    };
    this.setState({ numberBar, numberEnum: [...numberDefaultEnum] });
  }

  /* ------ tag设置相关 ------ */

  // 暂停
  setPause(tag) {
    const { textList, pauseBar } = this.state;
    const { idx } = pauseBar;
    if (tag === '移除') {
      textList.splice(idx, 1);
    } else {
      textList[idx].tag = tag;
    }
    this.setState({ textList, pauseBar: { ...pauseBar, show: false } });
    this.props.onChange(this.RTListTossml(textList, true));
    this.onAuditionHandle('stop', true);
  }

  addPause(tag) {
    const { textList, blurIdx } = this.state;
    const ahead = this.isInvalid(textList, blurIdx, false, false);
    const behind = this.isInvalid(textList, blurIdx, true, true);
    if (ahead || behind) {
      Utils.message.warning('不支持连续插入停顿符，请重新操作');
      return;
    }
    textList.splice(blurIdx, 0, pauseNode(tag));
    this.setState({ textList });
    this.props.onChange(this.RTListTossml(textList, true));
    this.onAuditionHandle('stop', true);
  }

  // 设置多音字
  setPolyphonic(tag, value) {
    const { textList, polyphonicBar } = this.state;
    const { idx } = polyphonicBar;
    const node = textList[idx];
    if (tag === '移除') {
      textList[idx] = textNode(node.text);
    } else {
      textList[idx] = polyphonicNode(tag, node.text, value);
    }
    this.setState({ textList }, () => this.onPopHide(false));
    this.props.onChange(this.RTListTossml(textList, true));
    this.onAuditionHandle('stop');
  }

  // 设置数字读音
  async setNumber(tag, barType) {
    const { textList, numberBar, selection } = this.state;
    const [startIdx, endIdx] = selection;
    const list = textList.slice(startIdx, endIdx);
    // 新建 直接添加数字多音节点
    if (barType === 'new') {
      let text = '';
      list.forEach((item) => (text += item.text));
      textList.splice(startIdx, endIdx - startIdx, numberNode(tag, text));
    } else {
      const { idx } = numberBar;
      const node = textList[idx] || {};
      if (tag === 'remove') {
        const numberTextList = node.text.split('').map((item) => textNode(item));
        textList.splice(idx, 1, ...numberTextList);
      } else {
        textList[idx] = numberNode(tag, node.text);
      }
    }

    this.setState({ textList }, () => this.onPopHide(false));
    this.props.onChange(this.RTListTossml(textList, true));
    this.onAuditionHandle('stop', true);
  }

  /* ------ 其他 ------ */

  // 试听气泡定位
  auditionPopShow() {
    const [startIdx, endIdx] = this.getSelection();
    const nodeDomList = this.nodeDomList.slice(startIdx, endIdx);
    // 获取选区左右范围，在中间位置添加气泡
    let rangeRight = 0;
    if (nodeDomList.length === 0) return;
    const { top: rangeTop, left: rangeLeft } = (nodeDomList[0] || nodeDomList[1])?.getBoundingClientRect();
    nodeDomList.forEach((item) => {
      if (!item) return;
      const { top, right } = item.getBoundingClientRect();
      if (top === rangeTop && right > rangeRight) rangeRight = right;
    });
    const audition = {
      show: true,
      left: rangeLeft + (rangeRight - rangeLeft) / 2,
      top: rangeTop - 35,
    };

    this.setState({ audition }, () => this.onPopHide(true));
  }

  onAuditionHandle(type, isInterrupt) {
    if (type === 'init') {
      this.onAuditionInit();
    } else {
      this.props.onAuditionHandle(type, null, null, isInterrupt);
      if (type === 'stop') {
        this.setState({
          sentenceSection: [],
          selection: [0, 0, ''],
          blurIdx: -1,
        });
        this.onPopHide(false);
      }
    }
  }

  // 播放文字初始化：1 分段(50字后第一个标点截断)，2 记录分段节点位置 3，转为模型可识别的ssml
  onAuditionInit() {
    const { inputIdx, blurIdx, globalSpeed, selection } = this.state;
    let { textList } = this.state;
    let [startIdx] = selection;
    const [endIdx] = selection;
    // 光标在最前，最后，无光标
    if (inputIdx === textList.length || (blurIdx === -1 && startIdx === endIdx)) {
      // console.error('从头到尾播放 1');
    } else if (blurIdx !== -1) {
      if (blurIdx === textList.length) {
        // console.error('从头到尾播放 2');
      } else {
        textList = textList.slice(blurIdx); // 选中区间的文字
        // console.error('从光标到尾播放');
      }
    } else {
      // console.error('选中文字播放');
      textList = textList.slice(startIdx, endIdx); // 选中区间的文字
    }
    const sentenceList = []; // 分段语句列表
    const sentenceSection = []; // 截断位置
    let floor = [];
    let idx = 0;
    let sentenceIdx = 0;
    let cacheList = [];
    const textRegExp = /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/;

    const append = (item) => {
      idx += item.text?.length || 0;
      cacheList.push(item);
    };
    const end = (item) => {
      sentenceList[sentenceIdx] = [...cacheList, item];
      cacheList = [];
      sentenceIdx += 1;
      idx = 0;
    };
    if (startIdx === endIdx) startIdx = blurIdx === -1 ? 0 : blurIdx;

    // 确定起始位置
    floor.push(startIdx);
    textList.forEach((item, i) => {
      const { text, type } = item;
      const hasSymbol = !textRegExp.test(text);
      // 遇换行截断
      if (type === 'wrap') {
        floor.push(startIdx + i);
        sentenceSection.push(floor);
        floor = [startIdx + i + 1];
        end(item);
      } else if (idx >= 50) {
        // 有标点，截断
        if (hasSymbol) {
          floor.push(startIdx + i);
          sentenceSection.push(floor);
          floor = [startIdx + i + 1];
          end(item);
        } else {
          append(item);
        }
      } else {
        append(item);
      }
    });
    // 结尾位置
    floor.push(startIdx + textList.length - 1);
    sentenceSection.push(floor);
    sentenceList[sentenceIdx] = [...cacheList];

    const ssmlList = sentenceList.map((item) => this.RTListTossml(item, false));
    this.setState({ sentenceSection, ssmlList, selection: [0, 0, ''] });
    this.props.onAuditionHandle('init', ssmlList, 2 - globalSpeed);
  }

  // 隐藏所有pop
  onPopHide(tigger) {
    const func = () => {
      const { pauseBar, polyphonicBar, numberBar } = this.state;
      this.setState({
        pauseBar: {
          ...pauseBar,
          show: false,
        }, // 需要清空语速bar
        polyphonicBar: {
          ...polyphonicBar,
          show: false,
        },
        numberBar: {
          ...numberBar,
          show: false,
        },
        playingIdx: -1,
      });
    };
    // 需要触发
    if (tigger) {
      window.onmousedown = null;
      window.onmousedown = func;
    } else {
      // 直接执行
      func();
    }
  }

  // 停止播放
  stopAudition() {
    const { audition } = this.state;
    this.setState({
      audition: {
        ...audition,
        show: false,
        sentenceSection: [],
      },
    });
    this.props.onAuditionHandle('stop');
  }

  /* ------ modal ------ */
  modalHandle(key, show, type) {
    let modal = this.state[key];
    modal = { ...modal, show, ...(type && { type }) };
    this.setState({ [key]: modal });
  }

  // 上传
  onUpload(uploadType) {
    this.fileRef.click();
    this.setState({ uploadType });
  }

  async onFileChange(e) {
    const {
      uploadType,
      textList,
      size,
      insertInfo: { insertIdx },
    } = this.state;
    const { files } = e.target;
    if (files.length === 0) return;
    const file = files[0];
    const { name } = file;
    const suffix = name.slice(name.lastIndexOf('.'));
    let text = '';
    if (suffix === '.docx') {
      text = await utils.docxToString(file);
    } else if (suffix === '.txt') {
      text = await utils.txtToString(file);
      // 如果有乱码，用gb2312编码再试一次
      if (text.indexOf('�') > -1) text = await utils.txtToString(file, 'gb2312');
    } else {
      alert('格式错误，仅支持.docx与.txt的文档');
    }
    const insertList = textToRTList(text);
    if (uploadType === 'cover') {
      this.insertText({ type: '导入', insertRange: [0, textList.length], insertList });
    } else if (uploadType === 'insert') {
      const insertFunc = (insertInfo) => {
        this.setState({ insertConfirmModal: { show: true }, insertInfo, size: size + text.length });
      };
      this.insertText({ type: '导入', insertIdx, insertList, insertFunc });
    }
    this.importModalHandle(false);
    this.fileRef.value = '';
  }

  // 插入前校验,
  onInsert() {
    const { size } = this.state;
    if (size === this.props.maxSize) {
      this.modalHandle('preventModal', true, '导入');
      this.importModalHandle(false);
    } else {
      this.onUpload('insert');
    }
  }

  /*
   * @params type              string          插入类型  输入，导入，粘贴
   * @params insertIdxOrRange  number | array  插入坐标  1 | [1,2]
   * @params insertList        array           插入列表  RTList
   * */
  // 1 插入文本（入口）
  insertText({ type, insertIdxParam, insertRange, insertList: insertListParam, insertFunc }) {
    let insertIdx = insertIdxParam;
    let insertList = insertListParam;

    const { maxSize } = this.props;
    const { size, textList } = this.state;

    let insertSize = 0;
    insertList.forEach((item) => {
      insertSize += item.text?.length || 0;
    });
    // 光标插入
    if (insertRange) {
      // 选区插入
      const [startIdx, endIdx] = insertRange;
      const delSize = endIdx - startIdx;
      // 超长，只能插入部分
      if (size - delSize + insertSize > maxSize) {
        const insertMaxSize = maxSize - size + delSize;
        insertList = insertList.splice(0, insertMaxSize);
        this.insertPartText({ insertRange, insertList, type });
      } else {
        this.insertFullText({ insertRange, insertList, type });
      }
    } else {
      if (insertIdx === -1) insertIdx = textList.length;
      // 禁止导入
      if (size >= maxSize) {
        this.modalHandle('preventModal', true, type);
        this.importModalHandle(false);
        return;
      }
      // 超长，只能插入部分
      if (size + insertSize > maxSize) {
        const insertMaxSize = maxSize - size;
        insertList = insertList.splice(0, insertMaxSize);
        this.insertPartText({ insertIdx, insertList, type });
      } else {
        if (insertFunc) {
          insertFunc({ insertIdx, insertList, type });
        } else {
          this.insertFullText({ insertIdx, insertList });
        }
      }
    }
  }

  // 2 插入部分文本
  insertPartText({ insertIdx, insertRange, insertList, type }) {
    this.setState({
      insertInfo: {
        ...(insertIdx && { insertIdx }),
        ...(insertRange && { insertRange }),
        insertList,
      },
    });
    this.modalHandle('partModal', true, type);
  }

  // 3 部分插入确认
  insertPartConfirm() {
    const {
      insertInfo: { insertIdx, insertRange, insertList },
      textList,
    } = this.state;
    let { size } = this.state;

    let insertSize = 0;
    insertList.forEach((item) => {
      insertSize += item.text?.length || 0;
    });

    if (insertRange) {
      const [startIdx, endIdx] = insertRange;
      size = size - (endIdx - startIdx) + insertSize;
      textList.splice(startIdx, endIdx - startIdx, ...insertList);
      // console.error('insertPartConfirm 选区 插入部分成功');
    } else {
      size = size + insertSize;
      textList.splice(insertIdx, 0, ...insertList);
      // console.error('insertPartConfirm 光标 插入部分成功');
    }
    this.setState({ textList, size });
    this.partModalHide();
    this.props.onChange(this.RTListTossml(textList, true));
  }

  // 2 普通插入
  async insertFullText({ insertIdx, insertRange, insertList }) {
    const { textList } = this.state;
    let { size, inputIdx } = this.state;

    let insertSize = 0;
    insertList.forEach((item) => {
      insertSize += item.text?.length || 0;
    });
    // 光标改变
    if (inputIdx !== -1) inputIdx += insertSize;
    if (insertRange) {
      const [startIdx, endIdx] = insertRange;
      inputIdx = startIdx + insertList.length;
      size = size - (endIdx - startIdx) + insertSize;
      textList.splice(startIdx, endIdx - startIdx, ...[...insertList, inputNode]);
      await this.setStateSync({ inputIdx, textList, size });
      this.inputRef.focus();
      // console.error('正常选区', type);
    } else {
      size = size + insertSize;
      textList.splice(insertIdx, 0, ...insertList);
      this.setState({ inputIdx, textList, size });
      // console.error('正常光标', type, inputIdx);
    }
    this.props.onChange(this.RTListTossml(textList, true));
  }

  // 导入确认
  insertConfirm(confirm) {
    if (confirm) {
      const {
        insertInfo: { insertIdx, insertList = [] },
        textList,
      } = this.state;
      textList.splice(insertIdx, 0, ...insertList);
      this.setState({ textList });
      this.props.onChange(this.RTListTossml(textList));
    }
    this.setState({ insertConfirmModal: { show: false }, insertInfo: {} });
  }

  partModalHide() {
    const { partModal } = this.state;
    partModal.show = false;
    this.setState({ partModal });
  }

  // 暂停是否不可用
  isInvalid(textList, idx, behind = true /* 向后检测 */, selfStart) {
    let n = -1;
    if (behind) {
      n = selfStart ? 0 : 1;
    }
    let invalid = false;
    while (textList[idx + n] && invalid === false) {
      const node = textList[idx + n];
      const { type, text } = node;
      if (['input', 'wrap'].includes(type)) {
        if (behind) {
          n += 1;
        } else {
          n -= 1;
        }
      } else if (type === 'text' && [' ', ' '].includes(text)) {
        // 这两个空格不一样,编辑器中的空格和浏览器中的空格有差别
        if (behind) {
          n += 1;
        } else {
          n -= 1;
        }
      } else if (type === 'pause') {
        invalid = true;
        break;
      } else {
        break;
      }
    }
    return invalid;
  }

  render() {
    const { header, headerExtra, maxSize, status, placeholder } = this.props;
    const {
      textList,
      polyphonicEnum,
      numberEnum,
      composition,
      size,
      globalSpeed,
      slideShow,
      pauseBar,
      polyphonicBar,
      numberBar,
      inputIdx,

      partModal,
      preventModal,
      importModal,
      insertConfirmModal,

      playingIdx,
      sentenceSection,
    } = this.state;
    const focus = inputIdx > -1;
    const [startIdx, endIdx] = sentenceSection[playingIdx] || [-1, -1];
    const textListLen = textList.length;
    const buttonDisabled = status !== 'success';
    const noText = textListLen === 0 || (textListLen === 1 && focus);

    return (
      <div className="rich-text" onContextMenu={(e) => e.preventDefault()}>
        {/* ------ 头部按钮 ------ */}
        <div className="rt-header">
          <div>{header}</div>
          <Space size={10}>
            {headerExtra}
            <div className="line" />
            <Button
              icon="rticon-yin"
              iconSize={18}
              disabled={buttonDisabled || noText}
              onClick={this.globalPolyphonicCheck.bind(this)}
            >
              检测常见多音字
            </Button>
            <div className="speed-box">
              <Button
                style={{ width: 120, justifyContent: 'space-between' }}
                icon="rticon-speed"
                iconSize={18}
                showArrow
                disabled={buttonDisabled || noText}
                onClick={this.sliderHandle.bind(this, !slideShow)}
              >
                <span className="speed">{`${parseInt(globalSpeed * 100, 10)}%`}</span>
                <span className="text">朗读语速</span>
              </Button>
              <Pop visible={slideShow} type="absolute" hasMask position={{ left: -82, top: 37 }}>
                <Slider className="rt-speed-slider" value={globalSpeed} onSlideChange={this.onSpeedChange.bind(this)} />
              </Pop>
            </div>
            <div className="line" />
            <Button
              icon="rticon-import"
              iconSize={18}
              disabled={buttonDisabled}
              onMouseDown={this.importModalHandle.bind(this, true, inputIdx)}
            >
              导入文本
            </Button>
          </Space>
        </div>
        {/* ------ 滚动 ------ */}
        <div className="rt-content">
          <Scroll
            className="rt-context"
            id="rt-context"
            onScroll={this.onCtxScroll.bind(this)}
            onMouseDown={this.onCtxMouseDown.bind(this)}
            onMouseUp={this.onCtxMouseUp.bind(this)}
            onCopy={this.onCtxCopy.bind(this)}
            onCut={this.onCtxCut.bind(this)}
            onPaste={this.onCtxPaste.bind(this)}
          >
            {/* eslint-disable-next-line operator-linebreak */}
            {textListLen > 0
              && textList.map((item, idx) => {
                const { key, type, text, tag } = item;
                const active = idx >= startIdx && idx <= endIdx;
                switch (type) {
                  case 'text':
                    return (
                      <b
                        key={key}
                        className={classNames('rt-node', 'rt-text', { active })}
                        idx={idx}
                        ref={(node) => (this.nodeDomList[idx] = node)}
                        onClick={this.cursorInsert.bind(this, idx)}
                        onMouseUp={this.onSelectEnd.bind(this)}
                        dangerouslySetInnerHTML={{
                          __html: [' ', ' '].includes(text) ? '&nbsp;' : text,
                        }}
                      />
                    );
                  case 'wrap':
                    return (
                      <br
                        key={key}
                        className="rt-node rt-wrap"
                        idx={idx}
                        ref={(node) => (this.nodeDomList[idx] = node)}
                      />
                    );
                  case 'pause': {
                    const invalid = this.isInvalid(textList, idx, true);
                    return (
                      <PauseNode
                        key={key}
                        idx={idx}
                        item={item}
                        invalid={invalid}
                        ref={(node) => (this.nodeDomList[idx] = node)}
                        onClick={this.cursorInsert.bind(this, idx)}
                        onMouseUp={this.onSelectEnd.bind(this)}
                        onNodeClick={this.pauseNodeClick.bind(this, idx)}
                      />
                    );
                  }
                  case 'polyphonic':
                    return (
                      <PolyphonicNode
                        key={key}
                        idx={idx}
                        item={item}
                        active={active}
                        ref={(node) => (this.nodeDomList[idx] = node)}
                        onClick={this.cursorInsert.bind(this, idx)}
                        onMouseUp={this.onSelectEnd.bind(this)}
                        onNodeClick={this.polyphonicNodeClick.bind(this, idx, false)}
                      />
                    );
                  case 'number':
                    return (
                      <PolyphonicNode
                        key={key}
                        idx={idx}
                        active={active}
                        item={{ ...item, tag: numberMap[item.tag] }}
                        ref={(node) => (this.nodeDomList[idx] = node)}
                        onClick={this.cursorInsert.bind(this, idx)}
                        onMouseUp={this.onSelectEnd.bind(this)}
                        onNodeClick={this.numberNodeClick.bind(this, { idx, tag })}
                      />
                    );
                  case 'input':
                    return (
                      <b key="input" className={classNames('rt-node rt-input', { composition })}>
                        <span
                          contentEditable
                          suppressContentEditableWarning
                          className="input"
                          ref={(node) => (this.inputRef = node)}
                          onKeyDown={this.onCursorKeyDown.bind(this)}
                          onInput={this.onInputChange.bind(this)}
                          onCompositionStart={this.onInputCompositionStart.bind(this)}
                          onCompositionEnd={this.onInputCompositionEnd.bind(this)}
                          onPaste={this.onInputPaste.bind(this)}
                          onBlur={this.onInputBlur.bind(this)}
                          dangerouslySetInnerHTML={{ __html: '' }}
                        />
                      </b>
                    );
                  default:
                    return null;
                }
              })}

            {/* ------ placeholder ------ */}
            {(textListLen === 0 || (textListLen === 1 && focus)) && (
              <span
                className="rt-placeholder"
                onMouseDown={(e) => e.preventDefault()}
                onClick={this.focusCursor.bind(this)}
              >
                {placeholder}
              </span>
            )}
          </Scroll>
          {/* ------ 停顿设置 ------ */}
          <div className="rt-setting-row">
            <Space size={6}>
              <span className="rt-setting-label">连读/停顿设置：</span>
              {pauseEnum.map((item, idx) => {
                if (item.value === '移除') return null;
                return (
                  <Button
                    key={`item-${idx}`}
                    disabled={!focus || buttonDisabled}
                    disabledTimeout={200}
                    onClick={this.addPause.bind(this, item.value)}
                  >
                    {item.label}
                  </Button>
                );
              })}
            </Space>
            <div className="rt-word-count">
              <span className="tr-count">{size}</span> / {maxSize}
            </div>
          </div>
        </div>

        {/* ------ 停顿选项 ------ */}
        <Pop visible={!!pauseBar.show} position={{ left: pauseBar.left, top: pauseBar.top }}>
          <OptionsBar theme="blue" left={pauseBar.left}>
            {pauseEnum.map((item, idx) => (
              <OptionsBar.Item
                key={idx}
                active={pauseBar.value === item.value}
                onClick={this.setPause.bind(this, item.value)}
              >
                {item.label}
              </OptionsBar.Item>
            ))}
          </OptionsBar>
        </Pop>

        {/* ------ 多音字选项 ------ */}
        <Pop visible={!!polyphonicBar.show} position={{ left: polyphonicBar.left, top: polyphonicBar.top }}>
          <OptionsBar theme="green" left={polyphonicBar.left}>
            {polyphonicEnum.map((item, idx) => (
              <OptionsBar.Item
                key={idx}
                active={polyphonicBar.value === item.label}
                onClick={this.setPolyphonic.bind(this, item.label, item.value)}
              >
                {item.label}
              </OptionsBar.Item>
            ))}
          </OptionsBar>
        </Pop>

        {/* ------ 数字选项 ------ */}
        <Pop visible={!!numberBar.show} position={{ left: numberBar.left, top: numberBar.top }}>
          <OptionsBar theme="green" left={numberBar.left}>
            {numberEnum.map((item, idx) => (
              <OptionsBar.Item
                key={idx}
                active={numberBar.value === item.label}
                onClick={this.setNumber.bind(this, item.type, numberBar.type)}
              >
                {item.label}
              </OptionsBar.Item>
            ))}
          </OptionsBar>
        </Pop>

        {/* ------ 播放 ------ */}
        {/* <Audition
                    visible={audition.show}
                    total={ssmlList?.length || 0}
                    step={playingIdx}
                    process={audioProcess}
                    position={{ left: audition.left, top: audition.top }}
                    playState={playState}
                    onAuditionHandle={this.onAuditionHandle.bind(this)}
                /> */}

        {/* ------ Modal 导入文件 ------ */}
        <Modal
          visible={importModal.show}
          title="导入文件"
          zIndex={3}
          footer={null}
          onCancel={this.importModalHandle.bind(this, false)}
        >
          <Space className="rt-import-box" size={20}>
            <div className="rt-import-item" onClick={this.onUpload.bind(this, 'cover')}>
              <Icon name="rticon-cover" size={22} />
              <div className="text-white">覆盖原文本</div>
              <div className="text-gray">替换已有文本</div>
            </div>
            <div className="rt-import-item" onClick={this.onInsert.bind(this, 'insert')}>
              <Icon name="rticon-insert" size={20} />
              <div className="text-white">插入新文本</div>
              <div className="text-gray">在光标处插入新文本</div>
            </div>
          </Space>
          <input
            type="file"
            ref={(node) => (this.fileRef = node)}
            style={{ display: 'none' }}
            accept=".docx,.txt"
            onChange={this.onFileChange.bind(this)}
          />
        </Modal>

        {/* ------ Modal 不允许导入文本 ------ */}
        <Modal
          visible={preventModal.show}
          title={`不允许${preventModal.type}文本`}
          cancelText={null}
          onOk={this.modalHandle.bind(this, 'preventModal', false, null)}
          onCancel={this.modalHandle.bind(this, 'preventModal', false, null)}
        >
          <div className="text-box">
            <div className="text-center">
              您{preventModal.type}的文本已到达上限，无法继续{preventModal.type}，
            </div>
            <div className="text-center">请您重新编辑文本后再上传</div>
          </div>
        </Modal>

        {/* ------ Modal 部分文本 ------ */}
        <Modal
          visible={partModal.show}
          title={`无法${partModal.type}全部文本`}
          okText={`确认${partModal.type}`}
          onOk={this.insertPartConfirm.bind(this)}
          onCancel={this.partModalHide.bind(this)}
        >
          <div className="text-box">
            <div className="text-center">
              由于字数限制，您的文本将<span className="text-orange">不能全部{partModal.type}，</span>
            </div>
            <div className="text-center"> 请问您是否还要继续{partModal.type}？</div>
          </div>
        </Modal>

        {/* ------ Modal 导入确认 ------ */}
        <Modal
          visible={insertConfirmModal.show}
          title="导入文本确认"
          okText="确认导入"
          onOk={this.insertConfirm.bind(this, true)}
          onCancel={this.insertConfirm.bind(this, false)}
        >
          <div className="text-box">
            <div className="text-center">您的文本将导入到您目前光标所在位置后侧，</div>
            <div className="text-center">请问您是否确认导入？</div>
          </div>
        </Modal>
      </div>
    );
  }
}

RichText.defaultProps = {
  header: null,
  status: 'init',
  playState: 'standby',
  maxSize: 5000,
  placeholder: '',
  listenEvent: true,
  getPolyphonic: () => {},
  onAuditionHandle: () => {},
  onChange: () => {},
};

export default RichText;
