import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Modal, Form, Input } from 'antd';

import { apiUpdateSelfInfo } from '../../../../api/action/common';

import utils from '../../../../utils';

const formItemLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};

const ChangeUserInfo = (props) => {
  const { visible, loginName, onCloseModal } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) {
      form.resetFields();
      form.setFieldsValue({ loginName });
    }
  }, [visible, form]);

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      const { newPwd, newPwdCheck } = values;
      if (newPwd !== newPwdCheck) {
        utils.message.error('两次密码不一致，请检查');
      } else {
        apiUpdateSelfInfo(values).then(() => {
          utils.message.success('修改成功');
          onCloseModal();
        });
      }
    });
  };

  return (
    <Modal
      width={500}
      title="修改密码"
      visible={visible}
      onOk={handleSubmit}
      onCancel={onCloseModal}
      maskClosable={false}
      forceRender={true}
    >
      <Form name="user-edit" form={form} layout="horizontal" {...formItemLayout}>
        <Form.Item
          label="登录账号"
          name="loginName"
          rules={[utils.verifyRule.required, utils.verifyRule.loginName]}
          normalize={utils.trim}
        >
          <Input placeholder="请填写" disabled={true} />
        </Form.Item>
        <Form.Item
          label="旧密码"
          name="oldPwd"
          rules={[utils.verifyRule.required, utils.verifyRule.pwd]}
          normalize={utils.trim}
        >
          <Input.Password placeholder="请填写" />
        </Form.Item>
        <Form.Item
          label="新密码"
          name="newPwd"
          rules={[utils.verifyRule.required, utils.verifyRule.pwd]}
          normalize={utils.trim}
        >
          <Input.Password placeholder="请填写" />
        </Form.Item>
        <Form.Item
          label="确认新密码"
          name="newPwdCheck"
          rules={[utils.verifyRule.required, utils.verifyRule.pwd]}
          normalize={utils.trim}
        >
          <Input.Password placeholder="请填写" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default connect(
  ({ commonInfo: { loginName } }) => ({ loginName }),
  (dispatch) => ({ dispatch }),
)(ChangeUserInfo);

ChangeUserInfo.propTypes = {
  visible: PropTypes.bool,
  onCloseModal: PropTypes.func,
};

ChangeUserInfo.defaultProps = {
  visible: false,
  onCloseModal: () => {},
};
