import React, { forwardRef } from 'react';
import { Icon } from '../index';

const PauseNode = forwardRef((props, ref) => {
  const {
    idx,
    item: { tag },
    invalid,
    onClick,
    onMouseUp,
    onNodeClick,
  } = props;

  return invalid ? (
    <div className="rt-node rt-pause-invalid" idx={idx} ref={ref} onClick={onClick} onMouseUp={onMouseUp}>
      <span className="rt-pause-invalid-inner" onClick={onNodeClick}>
        <Icon className="rt-pause-icon" name="rticon-error" multiColor />
        无效配置：{tag}
        {!!parseFloat(tag) && 's'}
      </span>
    </div>
  ) : (
    <div className="rt-node rt-pause" ref={ref} onClick={onClick} onMouseUp={onMouseUp}>
      <span className="rt-pause-inner" onClick={onNodeClick}>
        {tag}
        {!!parseFloat(tag) && 's'}
      </span>
    </div>
  );
});

PauseNode.displayName = 'PauseNode';

PauseNode.defaultProps = {
  idx: 0,
  item: {},
  invalid: false,
  onClick: () => {},
  onMouseUp: () => {},
  onNodeClick: () => {},
};

export default PauseNode;
