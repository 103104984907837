import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import './options-bar.scss';

const OptionsBar = (props) => {
  const { theme, left, children } = props;
  const [transform, setTransForm] = useState(0);
  const barRef = useRef();

  useEffect(() => {
    const right = left + children.length * 53 + 1;
    if (right > window.innerWidth) {
      setTransForm((children.length - 1) * 53);
    } else {
      setTransForm(0);
    }
  }, [children]);

  return (
    <div
      className={classNames('rt-options-bar', theme)}
      ref={barRef}
      style={transform ? { transform: `translateX(-${transform}px)` } : {}}
    >
      <i className="rt-arrow" style={transform ? { transform: `translateX(${transform}px)` } : {}}>
        <i className="rt-arrow-border" />
        <i className="rt-arrow-inner" />
      </i>
      {props.children}
    </div>
  );
};

const Item = (props) => {
  const { active } = props;
  return (
    <div className={classNames('rt-options-item', { active })} onClick={props.onClick}>
      {props.children}
    </div>
  );
};

OptionsBar.defaultProps = {
  theme: '',
};

Item.defaultProps = {
  active: false,
};

OptionsBar.Item = Item;

export default OptionsBar;
