import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { Menu } from 'antd';

import { ROUTE_ICON } from '../../../../config/router';
import { ROLE_TYPE_SUPER_ADMIN } from '../../../../page/system/const';
import utils from '../../../../utils';

class Titlebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedKeys: [],
    };
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (nextProps.location.pathname !== prevState.selectedKeys[0]) {
      return {
        selectedKeys: [nextProps.location.pathname],
      };
    }

    return null;
  };

  render() {
    const { sysMenu } = this.props;
    const { selectedKeys } = this.state;

    const titlebarMenu = sysMenu.map((i1) => {
      if (i1.secondMenus.length > 1) {
        return (
          <Menu.SubMenu
            key={i1.key}
            title={
              <span>
                {ROUTE_ICON[i1.name]}
                {i1.name}
              </span>
            }
          >
            {i1.secondMenus.map(({ key, name, linkUrl, hideEntrance }) =>
              hideEntrance ? null : (
                <Menu.Item key={key}>
                  <Link to={linkUrl}>{name}</Link>
                </Menu.Item>
              ),
            )}
          </Menu.SubMenu>
        );
      }
      if (i1.secondMenus.length === 1) {
        const { key, name, linkUrl, hideEntrance } = i1.secondMenus[0];
        return hideEntrance ? null : (
          <Menu.Item key={key}>
            <Link to={linkUrl}>
              {ROUTE_ICON[name]}
              {name}
            </Link>
          </Menu.Item>
        );
      }
      return null;
    });

    return (
      <div className="titlebar-wp">
        <Menu
          className="titlebar"
          mode="horizontal"
          selectedKeys={selectedKeys}
          style={{ fontSize: '16px', fontWeight: 'bold', lineHeight: '52px' }}
        >
          <Menu.Item key="homepage-default">
            <Link to={utils.fixSysPageUrl('')}>
              {ROUTE_ICON['首页']}
              首页
            </Link>
          </Menu.Item>
          {titlebarMenu}
        </Menu>
      </div>
    );
  }
}

export default withRouter(
  connect(({ commonInfo: { sysMenu, userRoleType } }) => {
    if (userRoleType.includes(ROLE_TYPE_SUPER_ADMIN)) {
      const superAdminExclude = [
        utils.fixSysPageUrl('/system/menu'),
        utils.fixSysPageUrl('/system/user'),
        utils.fixSysPageUrl('/system/role'),
      ];
      return {
        sysMenu: sysMenu.map((i1) => ({
          ...i1,
          secondMenus: i1.secondMenus.filter((i2) => !superAdminExclude.includes(i2.linkUrl)),
        })),
        userRoleType,
      };
    }
    return { sysMenu, userRoleType };
  })(Titlebar),
);
