import request from './request';
import queryString from 'query-string';

import apiMap from './api-map';

import utils from '../utils';

const errorHandle = (res) => {
  utils.message.error(res.message);
};

const apiFetch = (opts) => {
  const { apiName } = opts;
  if (!apiName) {
    console.error('apiName is not defined!');
    return;
  }
  // 检测并获取URL
  if (apiMap[apiName]) {
    const url = apiMap[apiName];
    const autoHandle = !(opts.autoHandle === false);
    return new Promise((resolve, reject) => {
      request({ ...opts, url })
        .then((res) => {
          resolve(res);
        })
        .catch((res) => {
          autoHandle && errorHandle(res);
          reject(res);
        });
    });
  }
  console.error(`api[${apiName}] is not in apiMap!`);
  return new Promise(() => {});
};

const apiGet = (apiName, params, opts) => apiFetch({ apiName, method: 'get', params, ...opts });
const apiGetJson = (apiName, params, opts) => apiFetch({ apiName, method: 'get', dataType: 'json', params, ...opts });
const apiPost = (apiName, params, opts) => apiFetch({ apiName, method: 'post', params, ...opts });
const apiPostJson = (apiName, params, opts) => apiFetch({ apiName, method: 'post', dataType: 'json', params, ...opts });
// 当apiForm为非formData结构时候，会执行new FormData，详见request.js
const apiForm = (apiName, params, opts) => apiFetch({ apiName, method: 'post', dataType: 'formData', params, ...opts });
// 链接形式
const apiLink = (apiName, params) => `${apiMap[apiName]}?${queryString.stringify(params)}`;

export { apiGet, apiGetJson, apiPost, apiPostJson, apiForm, apiLink };
