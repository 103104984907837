import React, { Fragment, useState, useEffect } from 'react';
import { Icon, Process, Loading, Pop } from './index';
import classNames from 'classnames';
import './audition.scss';

const Audition = (props) => {
  const { playState, position, total, step, process } = props;
  const [visible, setVisible] = useState(false);
  const [status, setStatus] = useState('standby'); // 状态 5种 standby loading playing pause stop

  useEffect(() => {
    if (playState !== status) setStatus(playState);
    if (playState === 'stop') setVisible(false);
  }, [playState]);

  useEffect(() => {
    if (props.visible === true && visible === false) {
      setStatus('standby');
      setVisible(true);
    }
    if (props.visible === false && visible === true) {
      setVisible(false);
    }
  }, [props.visible]);

  // 指令 4个 init pause play stop
  const onClick = (state, e) => {
    e.stopPropagation();
    props.onAuditionHandle(state);
  };

  const stop = (e) => e.stopPropagation();

  return (
    <Pop visible={visible} position={{ left: position.left, top: position.top }}>
      <div className={classNames('rt-audition', status)}>
        {status === 'standby' && (
          <div className="rt-audition-button play" onClick={(e) => onClick('init', e)}>
            <Process className="rt-audition-process" center={<Icon name="rticon-play" />} />
            试听
          </div>
        )}
        {status === 'loading' && (
          <div className="rt-audition-button loading" onMouseDown={stop}>
            <Loading className="rt-audition-loading" size={15} />
            生成中
          </div>
        )}
        {['playing', 'pause', 'stop'].includes(status) && (
          <Fragment>
            {status === 'pause' ? (
              <div className="rt-audition-button continue" onClick={(e) => onClick('play', e)} onMouseDown={stop}>
                <Process
                  className="rt-audition-process"
                  center={<Icon name="rticon-play" />}
                  status={status}
                  total={total}
                  step={step}
                  process={process}
                />
                继续
              </div>
            ) : (
              <div className="rt-audition-button pause" onClick={(e) => onClick('pause', e)} onMouseDown={stop}>
                <Process
                  className="rt-audition-process"
                  center={<Icon name="rticon-pause" />}
                  status={status}
                  total={total}
                  step={step}
                  process={process}
                />
                暂停
              </div>
            )}
            <div className="rt-line" />
            <div className="rt-audition-button stop" onClick={(e) => onClick('stop', e)}>
              <Process className="rt-audition-process" center={<Icon name="rticon-stop" />} />
              停止
            </div>
          </Fragment>
        )}
        <div className="rt-arrow">
          <Icon name="rticon-pop-arrow" size={16} color="#ff5e2f" />
        </div>
      </div>
    </Pop>
  );
};

Audition.defaultProps = {
  playState: 'standby',
  position: {},
  onAuditionHandle: () => {},
};

export default Audition;
