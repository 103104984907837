import React, { lazy } from 'react';
import { HomeOutlined, DatabaseOutlined, TeamOutlined, HistoryOutlined } from '@ant-design/icons';

import utils from '../utils';

const SystemMenu = lazy(() => import('../page/system/view/menu'));
const SystemUser = lazy(() => import('../page/system/view/user'));
const SystemRole = lazy(() => import('../page/system/view/role'));
const SystemTanant = lazy(() => import('../page/system/view/tenant'));
const SystemProduct = lazy(() => import('../page/system/view/product'));

const LogList = lazy(() => import('../page/log/list'));

const Vdemo = lazy(() => import('../page/vdemo/index'));

const MpPoc = lazy(() => import('../page/mp/poc'));

const InterfaceList = lazy(() => import('../page/interface/list'));
const InterfaceRecordList = lazy(() => import('../page/interface/record-list'));
const InterfaceRecordStream = lazy(() => import('../page/interface/record-stream'));
const InterfaceChat = lazy(() => import('../page/interface/chat'));

const InterfaceListBroadcastApaas = lazy(() => import('../page/interface-broadcast-apaas/list'));

const Trtc = lazy(() => import('../page/trtc'));

const routerMap = {
  /* ------ 系统管理 ------ */
  SystemTanant: {
    path: utils.fixSysPageUrl('/system/tenant'),
    component: SystemTanant,
    name: '商户管理',
  },
  SystemUser: {
    path: utils.fixSysPageUrl('/system/user'),
    component: SystemUser,
    name: '用户管理',
  },
  SystemRole: {
    path: utils.fixSysPageUrl('/system/role'),
    component: SystemRole,
    name: '角色管理',
  },
  SystemMenu: {
    path: utils.fixSysPageUrl('/system/menu'),
    component: SystemMenu,
    name: '菜单管理',
    hideEntrance: true,
  },
  SystemProduct: {
    path: utils.fixSysPageUrl('/system/product'),
    component: SystemProduct,
    name: '产品模块管理',
  },
  LogList: {
    path: utils.fixSysPageUrl('/log/list'),
    component: LogList,
    name: '日志查询',
  },
  Vdemo: {
    path: utils.fixSysPageUrl('/vdemo/index'),
    component: Vdemo,
    name: '数字人演示平台',
  },
  MpPoc: {
    path: utils.fixSysPageUrl('/mp/poc'),
    component: MpPoc,
    name: '小程序poc管理',
  },
  InterfaceList: {
    path: utils.fixSysPageUrl('/interface/list'),
    component: InterfaceList,
    name: '接口列表',
  },
  InterfaceListBroadcastApaas: {
    path: utils.fixSysPageUrl('/interface-broadcast-apaas/list'),
    component: InterfaceListBroadcastApaas,
    name: '播报接口列表',
  },
  InterfaceRecordList: {
    path: utils.fixSysPageUrl('/interface/record-list'),
    component: InterfaceRecordList,
    name: '数据统计-使用量查询',
  },
  InterfaceRecordStream: {
    path: utils.fixSysPageUrl('/interface/record-stream'),
    component: InterfaceRecordStream,
    name: '数据统计-实时监控',
  },
  InterfaceChat: {
    path: utils.fixSysPageUrl('/interface/chat'),
    component: InterfaceChat,
    name: '对话管理',
  },
  Trtc: {
    path: utils.fixSysPageUrl('/trtc-pc/index'),
    component: Trtc,
    name: '人工坐席管理',
  },
};

const routerEnum = Object.values(routerMap);

const ROUTE_ICON = {
  首页: <HomeOutlined />,
  产品模块管理: <DatabaseOutlined />,
  商户管理: <TeamOutlined />,
  日志查询: <HistoryOutlined />,
};

export { routerMap, routerEnum, ROUTE_ICON };
