import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Header from './header';
import Content from './content';

import { apiLoginStatus } from '../../../../api/action/common';

class PageWp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.dispatch(apiLoginStatus());
  }

  render() {
    const {
      userId,
      children,
      headerSubTitle,
      headerRightHandle,
      footerHandle = null,
      style,
      showTitleBar = true,
      userContentCard = true,
    } = this.props;

    return (
      userId && (
        <div className="page-wp" style={style}>
          <Header showTitleBar={showTitleBar} />
          {userContentCard ? (
            <Content headerSubTitle={headerSubTitle} headerRightHandle={headerRightHandle}>
              {children}
            </Content>
          ) : (
            <div className="page-content-wp">{children}</div>
          )}
          <div className="page-footer">{footerHandle}</div>
        </div>
      )
    );
  }
}

export default withRouter(
  connect(
    ({ commonInfo: { userId } }) => ({ userId }),
    (dispatch) => ({ dispatch }),
  )(PageWp),
);
