import React from 'react';
import classNames from 'classnames';

import './process.scss';

const Process = (props) => {
  const { center, className, total, step, process, fill, stroke, size } = props;

  const scale = 360 / total;
  const deg = scale * (step + process);
  const halfSize = size / 2;
  const r = halfSize - 1;
  const d = 2 * r;

  return (
    <div className={classNames('rt-process', className)} style={{ width: size, height: size }}>
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} xmlns="http://www.w3.org/2000/svg">
        <circle
          className="progress-round"
          cx="0"
          cy="0"
          r={r}
          fill={fill}
          stroke={stroke}
          transform={`translate(${halfSize},${halfSize}) rotate(-90)`}
          strokeDasharray={`${(d * Math.PI * deg) / 360}, ${d * Math.PI}`}
          strokeDashoffset={0}
        />
        <circle cx={halfSize} cy={halfSize} r={r} className="process-circle" fill="none" />
      </svg>
      <div className="center">{center}</div>
    </div>
  );
};

Process.defaultProps = {
  center: null,
  className: '',
  total: 0,
  step: 0,
  process: 0,
  fill: '#fb5322',
  size: 22,
  stroke: 'rgba(255, 255, 255, 1)',
};

export default Process;
