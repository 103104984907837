import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Icon } from './index';
import './button.scss';

const Button = (props) => {
  const { className, icon, iconSize, showArrow, theme, style, disabledTimeout } = props;
  const timer = useRef();
  const debounceTimer = useRef({ timer: null });

  const [disabled, setDisabled] = useState(props.disabled);

  useEffect(() => {
    clearTimeout(timer.current);
    if (disabledTimeout && props.disabled) {
      timer.current = setTimeout(() => setDisabled(true), disabledTimeout);
    } else {
      setDisabled(props.disabled);
    }
  }, [props.disabled]);

  const onClick = (e) => {
    if (disabled) return;
    const { current } = debounceTimer;
    if (!current.timer) {
      current.timer = setTimeout(() => {
        current.timer = null;
      }, 300);
      props.onClick(e);
    }
  };
  return (
    <button
      className={classNames('rt-button', { disabled }, theme, className)}
      style={style}
      onClick={onClick}
      onMouseDown={props.onMouseDown}
    >
      {icon && <Icon className="btn-left-icon" name={icon} size={iconSize} />}
      <span className="btn-text">{props.children}</span>
      {showArrow && <Icon className="btn-right-icon" name="rticon-arrow-down-bold" />}
    </button>
  );
};

Button.defaultProps = {
  className: '',
  style: {},
  disabled: false,
  disabledTimeout: 0, // 延时disable，用于处理点击按钮input失焦问题
  icon: null,
  iconSize: 12,
  showArrow: false,
  theme: 'default', // default, orange
  onClick: () => {},
  onMouseDown: () => {},
};

export default Button;
