import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { UserOutlined } from '@ant-design/icons';

import { Button, Menu, Dropdown } from 'antd';
import Titlebar from './titlebar';
import ChangeUserInfo from './change-user-info';

import { apiLogout } from '../../../../api/action/common';

import { SYS_NAME } from '../../../../config/const';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfoModalVisible: false,
    };
  }

  logout = () => {
    apiLogout();
  };

  showChangeUserInfoModal = () => {
    this.setState({ userInfoModalVisible: true });
  };

  closeChangeUserInfoModal = () => {
    this.setState({ userInfoModalVisible: false });
  };

  render() {
    const { userName, showTitleBar } = this.props;
    const { userInfoModalVisible } = this.state;

    const rightMenu = (
      <Menu>
        <Menu.Item key="change-pwd" onClick={this.showChangeUserInfoModal} style={{ textAlign: 'center' }}>
          修改密码
        </Menu.Item>
        <Menu.Item key="logout" onClick={this.logout} style={{ textAlign: 'center' }}>
          退出
        </Menu.Item>
      </Menu>
    );
    return (
      <div className="page-header-wp">
        <div className="page-header flex-row">
          <div className="sys-name">{SYS_NAME}</div>
          <Dropdown className="right-Menu" overlay={rightMenu}>
            <Button type="primary flex-row">
              <UserOutlined />
              <b style={{ marginLeft: 5 }}>{userName}</b>
            </Button>
          </Dropdown>
        </div>
        {showTitleBar && <Titlebar />}
        <ChangeUserInfo onCloseModal={this.closeChangeUserInfoModal} visible={userInfoModalVisible} />
      </div>
    );
  }
}

export default withRouter(
  connect(({ commonInfo: { userName } }) => ({
    userName,
  }))(Header),
);
