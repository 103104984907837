import React, { useState, useEffect, Fragment } from 'react';
import classNames from 'classnames';
import { Icon, Button, Space } from './index';
import './modal.scss';

const Modal = (props) => {
  const { visible, maskClosable, title, footer, children, wrapClassName, zIndex, okText, cancelText } = props;

  const [state, setState] = useState('init');

  useEffect(() => {
    if (visible) {
      setState('show');
      document.body.onmousewheel = (e) => e.stopPropagation();
    } else {
      if (state === 'init') return;
      setState('hide');
      setTimeout(() => setState('none'), 300);
    }
  }, [visible]);

  const onCancel = () => {
    props.onCancel();
  };

  const onOk = () => props.onOk();

  return (
    <Fragment>
      <div
        className={classNames('rt-modal-mask', state, wrapClassName)}
        style={{ zIndex }}
        onClick={() => maskClosable && onCancel()}
      />
      <div className={classNames('rt-modal', state)} style={{ zIndex }}>
        <div className="rt-modal-header">
          {title}
          <div className="rt-modal-close" onClick={onCancel}>
            <Icon name="rticon-close" size={16} clolr="rgba(255,255,255,0.36)" />
          </div>
        </div>
        <div className="rt-modal-content">{children}</div>
        {footer && (
          <div className="rt-modal-footer">
            {footer === true ? (
              <Space size={30}>
                {cancelText && (
                  <Button className="rt-modal-button" onClick={onCancel}>
                    {cancelText}
                  </Button>
                )}
                {okText && (
                  <Button className="rt-modal-button confirm" theme="orange" onClick={onOk}>
                    {okText}
                  </Button>
                )}
              </Space>
            ) : (
              footer
            )}
          </div>
        )}
      </div>
    </Fragment>
  );
};

Modal.defaultProps = {
  visible: false,
  maskClosable: true,
  title: '',
  zIndex: 0,
  wrapClassName: '',
  okText: '确认',
  cancelText: '取消',
  footer: true,
  onCancel: () => {},
  onOk: () => {},
};

export default Modal;
