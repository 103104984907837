import { CHANGE_USER_INFO, CHANGE_USER_MENU, CHANGE_RESOURCE_ENUM } from './action-type';

const commonState = {
  userId: '',
  userName: '',
  loginName: '',
  userTenantId: '',
  userRoleType: [],
  sysMenu: [],
};
export const commonInfo = (state = commonState, action) => {
  switch (action.type) {
    case CHANGE_USER_INFO:
      return {
        ...state,
        userId: action.userId || '',
        userName: action.userName || '',
        loginName: action.loginName || '',
        userTenantId: action.userTenantId || '',
        userRoleType: action.userRoleType || [],
      };
    case CHANGE_USER_MENU:
      return {
        ...state,
        sysMenu: action.sysMenu || [],
      };
    case CHANGE_RESOURCE_ENUM:
      return {
        ...state,
        resourceEnum: action.resourceEnum || [],
      };
    default:
      return state;
  }
};
