import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Upload } from 'antd';

class UploadButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUploading: false,
    };
  }

  static propTypes = {
    uploadText: PropTypes.string,
    onUpload: PropTypes.func,
  };

  static defaultProps = {
    uploadText: '',
    onUpload: () => {},
  };

  render() {
    const { ...rest } = this.props;
    const uploadConfig = {
      beforeUpload: (file) => {
        this.props.onUpload(file);
        return false;
      },
    };

    return (
      <Upload {...rest} {...uploadConfig}>
        {this.props.children}
      </Upload>
    );
  }
}

export default UploadButton;
