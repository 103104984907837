import { apiPostJson, apiGet } from '../../utils';
import { CHANGE_USER_INFO, CHANGE_USER_MENU, CHANGE_RESOURCE_ENUM } from './action-type';
import { routerEnum } from '../../../config/router';

import { ONLINE_MENU_PRODUCTIDS, TEST_MENU_PRODUCTIDS } from '../../../config/const';

import utils from '../../../utils';

export const apiLoginMountFlagStatus = () =>
  apiGet('apiLoginMountFlagStatus', undefined, { autoHandle: false }).then((res) => res.data || {});

export const apiLoginImageCode = () => apiGet('apiLoginImageCode').then((res) => res.data || {});

export const apiLogin = ({ user, pwd, tenantId, isMain, code, codeKey }) =>
  apiPostJson('apiLogin', { user, pwd, tenantId, isMain, code, codeKey });

export const apiLoginUnion = ({ userAccountType, tenantId, mode, accessToken, expires, tokenType }) =>
  apiPostJson('apiLoginUnion', {
    userAccountType,
    tenantId,
    ext: { oauth2: { mode, accessToken, expires, tokenType } },
  });

export const apiLogout = () => {
  apiPostJson('apiLogout').then(({ data }) => {
    if (data.returnUrl) {
      // 联登处理
      window.location.replace(data.returnUrl);
    } else {
      utils.jumpLogin({ noRu: true });
    }
  });
};

export const apiLoginStatus = () => (dispatch) =>
  apiGet('apiLoginStatus', undefined, { autoHandle: false }).then(
    ({ data: { userId, name: userName, loginName, tenantId: userTenantId, roleTypes: userRoleType } }) => {
      dispatch({
        type: CHANGE_USER_INFO,
        userId,
        userName,
        loginName,
        userTenantId,
        userRoleType,
      });
      return { userId, userName, loginName, userRoleType };
    },
  );

export const apiGetLoginUser = () => (dispatch) =>
  apiGet('apiGetLoginUser').then(({ data: { name } }) => {
    dispatch({ type: CHANGE_USER_INFO, userName: name });
  });

// 查询所有菜单
export const apiGetAllMenu = () => apiGet('apiGetAllMenu').then((res) => res.data);

// 查询用户可访问的所有菜单
export const apiGetUserMenus = ({ autoHandle = true }) =>
  apiGet('apiGetUserMenus', {}, { autoHandle }).then((res) => res.data);

const fixMenu = (productMenu) => {
  const sysMenu = (productMenu || []).map(({ id, name, secondMenus = [] }) => ({
    key: `1-${id}`,
    id,
    name,
    secondMenus: secondMenus.map(({ id, name, linkUrl }) => {
      const { hideEntrance, path } = routerEnum.find(({ path }) => linkUrl.indexOf(path) > -1) || {};
      return { key: `2-${id}`, id, name, linkUrl, hideEntrance, path };
    }),
  }));
  return sysMenu;
};

// 查询数字人平台展示菜单路由
export const apiGetCurrentAllVisibleMenu = () => (dispatch) =>
  apiGetUserMenus({ autoHandle: false }).then((list) => {
    const curMenuProductIds = utils.isTestEnv() ? TEST_MENU_PRODUCTIDS : ONLINE_MENU_PRODUCTIDS;
    const menus = [];
    (list || []).forEach((i) => {
      if (curMenuProductIds.includes(i.id)) {
        menus.push(i.firstMenus || []);
      }
    });
    dispatch({ type: CHANGE_USER_MENU, sysMenu: fixMenu(menus.flat()) });
  });

// 按照产品模块查询用户分配菜单
export const apiGetProductMenu = (productId) => (dispatch) =>
  apiGet('apiGetProductMenu', { productId }, { autoHandle: false }).then((res) => {
    dispatch({ type: CHANGE_USER_MENU, sysMenu: fixMenu(res.data || {}) });
  });

// 修改个人信息
export const apiUpdateSelfInfo = ({ oldPwd, newPwd }) =>
  apiPostJson('apiUpdateSelfInfo', { oldPwd, newPwd }).then((res) => res.data);

// 获取所有资源类型
export const apiGetAllResourceTypes = () => (dispatch) =>
  apiGet('apiGetAllResourceTypes', undefined, { autoHandle: false }).then((res) => {
    dispatch({
      type: CHANGE_RESOURCE_ENUM,
      resourceEnum: res.data,
    });
  });
