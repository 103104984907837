import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { routerEnum } from '../../config/router';
import Homepage from '../../page/sys/homepage';
import Login from '../../page/sys/login';
import Page404 from '../../page/sys/404';

import { apiGetCurrentAllVisibleMenu, apiLoginUnion, apiGetAllResourceTypes } from '../../api/action/common';

import utils from '../../utils';

class UserRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoading: true,
    };
  }

  componentDidMount() {
    if (utils.urlParse('auth_code')) {
      const userAccountType = utils.urlParse('accountType');
      const accessToken = utils.urlParse('auth_code');
      const tenantId = utils.urlParse('tenantId');
      apiLoginUnion({ userAccountType, accessToken, tenantId }).then(() => {
        utils.loginSuccessJump('returnUrl');
      });
    } else {
      this.props.dispatch(apiGetCurrentAllVisibleMenu(utils.getCurProductId())).finally(() => {
        this.setState({ pageLoading: false });
      });
      this.props.dispatch(apiGetAllResourceTypes());
    }
  }

  render() {
    const { sysMenu } = this.props;
    const { pageLoading } = this.state;
    const menu = sysMenu.map((i1) => i1.secondMenus.map((i2) => i2.path)).flat();
    const routerList = routerEnum.filter((i) => i.isDefault || menu.includes(i.path));
    return pageLoading ? null : (
      <Switch>
        {routerList.map((i, idx) => (
          <Route exact={i.exact} key={idx} path={i.path} component={i.component} />
        ))}
        <Route path={utils.fixSysPageUrl('/login')} component={Login} />
        <Route path={utils.fixSysPageUrl('')} component={Homepage} exact />
        <Route path="/" component={Homepage} exact />
        <Route path="*" component={Page404} />
      </Switch>
    );
  }
}

export default connect(
  ({ commonInfo: { sysMenu } }) => ({ sysMenu }),
  (dispatch) => ({ dispatch }),
)(UserRouter);
