// import * as serviceWorker from './serviceWorker'
// serviceWorker.unregister()

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: http://bit.ly/CRA-PWA

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { WrapperConfigProvider } from './js/component/common';

import storeConfig from './js/config/store';

import UserRouter from './js/component/sys/user-router';

import './css/index.less';
import './css/index.scss';

ReactDOM.render(
  <WrapperConfigProvider>
    <Provider store={storeConfig()}>
      <BrowserRouter>
        <Suspense fallback={<div />}>
          <UserRouter />
        </Suspense>
      </BrowserRouter>
    </Provider>
  </WrapperConfigProvider>,
  document.getElementById('root'),
);
