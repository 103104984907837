import { apiGet, apiPostJson } from '../../api/utils';

// 获取所有产品模块
export const apiGetAllProduct = () => apiGet('apiGetAllProduct').then((res) => res.data);

// 查询用户产品模块列表
export const apiGetUserProduct = () => apiGet('apiGetUserProduct').then((res) => res.data);

// 新增产品模块
export const apiAddProduct = ({ name, description, linkUrl, icon, resourceTypes }) =>
  apiPostJson('apiAddProduct', { name, description, linkUrl, icon, resourceTypes }).then((res) => res.data);

// 编辑产品模块
export const apiUpdateProduct = ({ id, name, description, linkUrl, icon, resourceTypes }) =>
  apiPostJson('apiUpdateProduct', { id, name, description, linkUrl, icon, resourceTypes }).then((res) => res.data);

// 查询产品模块详情 - 借用获取所有产品模块接口
export const apiProductDetail = (productId) =>
  apiGet('apiGetAllProduct').then((res) => res.data.filter((i) => i.id.toString() === productId)[0] || {});
