import axios from 'axios';
import qs from 'qs';

import utils from '../utils';

import { API_DATA, API_CODE, API_MESSAGE, SUCCESS_CODE, NEED_LOGIN_CODE } from './const';

const request = (opts = {}) => {
  const url = typeof opts === 'string' ? opts : opts.url || '';
  const method = (opts.method || 'get').toLowerCase();
  const { dataType = '', responseType, needCommonParams = true } = opts;
  const extraConfig = { responseType };
  let { params = {} } = opts;

  if (opts.params instanceof FormData) {
    params.append('t', Date.now());
  } else if (needCommonParams) {
    params = { t: Date.now(), ...(opts.params || {}) };
  }

  if (url.length === 0) {
    console.warn('utils.request: url未指定');
    return;
  }

  return new Promise((resolve, reject) => {
    if (method === 'get' || method === 'delete') {
      params = { params, ...extraConfig };
    } else if (dataType === 'formData') {
      if (!(params instanceof FormData)) {
        const formData = new FormData();
        Object.keys(params).forEach((key) => formData.append(key, params[key]));
        params = formData;
      }
    } else if (dataType !== 'json') {
      params = qs.stringify(params);
    }
    (method === 'get' ? axios[method](url, params) : axios[method](url, params, extraConfig))
      .then((res) => {
        if (res.status === 200) {
          if (opts.responseType === 'blob') {
            if (res.headers['content-type'] === 'application/json') {
              // 错误处理
              const { [API_CODE]: code, [API_DATA]: data, [API_MESSAGE]: message } = res.data;
              reject({ data, code, message });
            } else {
              resolve(res.data);
            }
          }
          const { [API_CODE]: code, [API_DATA]: data, [API_MESSAGE]: message } = res.data;
          if (code === SUCCESS_CODE) {
            resolve({ data, code, message });
          } else if (NEED_LOGIN_CODE.includes(code)) {
            utils.jumpLogin();
            reject({ data, code, message });
          } else {
            reject({ data, code, message });
          }
        } else {
          reject({ code: res.status, data: res, msg: res.errMsg || '系统错误，请稍后重试！' });
        }
      })
      .catch((res) => {
        reject({ code: 500, data: res, msg: 'network error' });
      });
  });
};
export default request;
