/**
 * @param {Array} optlist 选项数组，格式{key, label}
 * @param {number} maxShowLength 最多展示搜索结果条目数
 * 其他参数见 antd Select 组件
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Select, Divider } from 'antd';

const debounce = require('lodash/debounce');

const Option = { Select };

class HugeDataSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterOptList: [],
      lowerCaseOptLabelList: [],
      initialOptList: [],
    };

    this.debounceLoadOption = debounce((e) => this.LoadOption(e), 300, {
      maxWait: 500,
    });
  }

  static propTypes = {
    optlist: PropTypes.array,
    maxShowLength: PropTypes.number,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    optlist: [],
    maxShowLength: 30,
    disabled: false,
    onChange: () => {},
  };

  componentDidMount() {
    this.initOptList(this.props);
  }

  /* eslint-disable camelcase */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.optlist !== nextProps.optlist || this.props.value !== nextProps.value) {
      this.initOptList(nextProps);
    }
  }

  initOptList({ maxShowLength, defaultVal, mode, value, optlist = [] }) {
    const initialOptList = optlist.slice(0, maxShowLength);

    if (mode !== 'multiple') {
      // 初始选项中包含默认选项
      const defaultOptIndex = optlist.findIndex((i) => i.key === defaultVal || i.key === value);

      if (defaultOptIndex > maxShowLength - 1) {
        initialOptList[maxShowLength - 1] = optlist[defaultOptIndex];
      }
    }

    this.setState({
      lowerCaseOptLabelList: optlist.map((i) => i.label.toLowerCase()),
      initialOptList,
      filterOptList: initialOptList,
    });
  }

  handleSearch(keyWords) {
    this.debounceLoadOption(keyWords);
  }

  LoadOption(keyWords) {
    const { optlist, maxShowLength } = this.props;
    const { lowerCaseOptLabelList, initialOptList } = this.state;

    let filterOptList = [];
    const lowerCaseKeyWords = keyWords.toLowerCase();

    if (lowerCaseKeyWords && lowerCaseOptLabelList.length > 0) {
      let count = 0;
      const optLength = optlist.length;
      for (let i = 0; i < optLength; i++) {
        if (count >= maxShowLength) break;
        if (lowerCaseOptLabelList[i].indexOf(lowerCaseKeyWords) > -1) {
          filterOptList.push(optlist[i]);
          count += 1;
        }
      }
    } else {
      filterOptList = initialOptList;
    }

    this.setState({ filterOptList });
  }

  handleSelectChange(result) {
    const { onChange } = this.props;
    onChange(result);
  }

  render() {
    const { filterOptList } = this.state;
    const { maxShowLength, defaultVal, ...rest } = this.props;

    return (
      <Select
        {...rest}
        defaultValue={defaultVal}
        showSearch
        onSearch={this.handleSearch.bind(this)}
        onChange={this.handleSelectChange.bind(this)}
        filterOption={false}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider style={{ margin: '4px 0' }} />
            <div style={{ padding: '2px 10px 7px 10px' }}>最多展示{maxShowLength}项搜索结果</div>
          </div>
        )}
        dropdownMatchSelectWidth={false}
      >
        {filterOptList.map((i) => (
          <Option value={i.key} key={i.key} title={i.label}>
            {i.label}
          </Option>
        ))}
      </Select>
    );
  }
}

export default HugeDataSelect;
