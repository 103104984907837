import React, { Component } from 'react';

import { PageWp } from '../../component/common';

import image404 from '../../../assets/404.gif';

class Page404 extends Component {
  render() {
    return (
      <PageWp>
        <div style={{ textAlign: 'center', marginTop: 100 }}>
          <img className="text-center" src={image404} style={{ width: 600 }} alt="404"></img>
        </div>
      </PageWp>
    );
  }
}

export default Page404;
