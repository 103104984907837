const verifyRule = {
  required: {
    required: true,
    message: '不得为空',
  },
  spaceInvalid: {
    validator: async (rule, value) => {
      if (/(^\s+)|(\s+$)|\s+/.test(value)) {
        throw new Error('有空格');
      }
    },
  },
  number: {
    pattern: /^[0-9]{1,}$/,
    message: '请输入数字',
  },
  positiveInt: {
    pattern: /^[1-9]\d*$/,
    message: '请输入大于等于1的整数',
  },
  tenantId: {
    pattern: /^[0-9]{1,6}$/,
    message: '请输入6位数字',
  },
  name: {
    pattern: /^[0-9a-zA-Z\u4e00-\u9fa5_]{1,50}$/,
    message: '不超过50字，中文、字母、数字或下划线_',
  },
  loginName: {
    pattern: /^[0-9a-zA-Z]{8,20}$/,
    message: '请输入8~20位字母或数字',
  },
  pwd: {
    validator: async (rule, value = '') => {
      if (!!value && (value.length < 8 || value.length > 16)) {
        throw new Error('请输入8~16位字母、数字及特殊字符');
      }
    },
  },
  lessthan: (num) => ({
    validator: async (rule, value = '') => {
      if (!!value && value.length > num) {
        throw new Error(`不超过${num}字`);
      }
    },
  }),
};

export default verifyRule;
