import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, PageHeader } from 'antd';

import { routerEnum } from './.././../../../config/router';

class Content extends Component {
  render() {
    const { match, headerSubTitle, headerRightHandle, children } = this.props;

    const curRoute = routerEnum.find((i) => i.path === match.path);

    const CardHeader = curRoute && (
      <PageHeader
        title={curRoute.name}
        subTitle={headerSubTitle}
        extra={headerRightHandle}
        style={{ padding: 0, lineHeight: '32px' }}
      />
    );

    return (
      <div className="page-content-wp">
        <Card title={CardHeader} bodyStyle={{ padding: '12px 24px' }}>
          {children}
        </Card>
      </div>
    );
  }
}

export default withRouter(Content);

Content.defaultProps = {
  headerSubTitle: null,
  headerRightHandle: null,
};
