import React, { Component } from 'react';

import { Form, Input, Select, Button } from 'antd';
import { BankOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';

import { apiLoginMountFlagStatus, apiLoginImageCode, apiLogin } from '../../api/action/common/index';

import utils from '../../utils';

import mainBg from '../../../assets/login-main-bg.png';
import '../../../css/page/login.scss';

import { SYS_NAME } from '../../config/const';

class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loginForm: {
        isMain: true,
        tenantId: '',
        user: '',
        pwd: '',
      },
      showFlag: 2,
      codeImage: '', // 图片验证码地址
      codeKey: '', // 图片验证码key
    };
  }

  componentDidMount = () => {
    this.changeFlagStatus();
  };

  // 修改图片验证码展示变量， 有输入时直接使用，无输入时请求获取
  changeFlagStatus = async (flag) => {
    const showFlag = flag || (await apiLoginMountFlagStatus().then(({ showFlag }) => showFlag));
    this.setState({ showFlag }, () => {
      switch (showFlag) {
        case 1:
          this.getLoginImageCode();
          break;
        case 2:
          this.setState({ codeImage: '', codeKey: '' });
      }
    });
  };

  getLoginImageCode = () => {
    apiLoginImageCode().then(({ codeKey, codeImage }) => {
      this.setState({
        codeImage: codeImage || '',
        codeKey: codeKey || '',
      });
    });
  };

  handleLogin = (values) => {
    const { showFlag, codeKey } = this.state;
    const { isMain, tenantId, user, pwd, code } = values;
    let data = { isMain, tenantId, user, pwd };
    switch (showFlag) {
      case 1:
        data = { ...data, code, codeKey };
        break;
      case 2:
        break;
    }
    apiLogin(data)
      .then(() => {
        utils.loginSuccessJump();
      })
      .catch(({ data }) => {
        const { showFlag } = data || {};
        this.changeFlagStatus(showFlag || 1);
      });
  };

  render() {
    const { showFlag, loginForm, codeImage } = this.state;

    return (
      <div className="com-page-wp login-page">
        <div className="main-bg">
          <img src={mainBg}></img>
        </div>
        <div className="main-content">
          <div className="sys-name flex-row">
            <span>{SYS_NAME}</span>
          </div>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={loginForm}
            style={{ width: 330 }}
            onFinish={this.handleLogin}
          >
            <Form.Item name="isMain" rules={[{ required: true, message: '' }]}>
              <Select placeholder="请选择账户类型">
                <Select.Option value={true}>主账户登录</Select.Option>
                <Select.Option value={false}>子账户登录</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.isMain !== currentValues.isMain}>
              {({ getFieldValue }) =>
                getFieldValue('isMain') === false ? (
                  <Form.Item name="tenantId" rules={[{ required: true, message: '' }, utils.verifyRule.tenantId]}>
                    <Input prefix={<BankOutlined />} placeholder="请输入商户ID" />
                  </Form.Item>
                ) : null
              }
            </Form.Item>
            <Form.Item name="user" rules={[{ required: true, message: '' }]}>
              <Input prefix={<UserOutlined />} placeholder="请输入用户名" />
            </Form.Item>
            <Form.Item name="pwd" rules={[{ required: true, message: '' }]}>
              <Input.Password prefix={<LockOutlined />} placeholder="请输入密码" />
            </Form.Item>
            {showFlag === 1 && (
              <Form.Item name="code" rules={[{ required: true, message: '请输入图片验证码' }]}>
                <Input
                  placeholder="请输入验证码"
                  maxLength={4}
                  size="small"
                  suffix={
                    <Button onClick={this.getLoginImageCode} className="login-image-code-btn" type="text">
                      <img src={codeImage} height="32" width="117" />
                    </Button>
                  }
                />
              </Form.Item>
            )}
            {/* <Form.Item>
                            <Form.Item name='remember' valuePropName='checked' noStyle>
                                <Checkbox>记住密码</Checkbox>
                            </Form.Item>
                        </Form.Item> */}
            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                登录
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default LoginPage;
