import React from 'react';
import classNames from 'classnames';
import './space.scss';

const Space = (props) => {
  const { size, children, className } = props;
  const childSpace = () => {
    if (Array.isArray(children)) {
      return children.flat(1).map((item, idx) =>
        item ? (
          <div key={idx} className="rt-space-item" style={{ marginRight: size }}>
            {item}
          </div>
        ) : null,
      );
    }
    return children;
  };

  return <div className={classNames('rt-space', className)}>{childSpace}</div>;
};

Space.defaultProps = {
  size: 5,
  className: '',
};

export default Space;
