import JSZip from 'jszip';

const utils = {
  docxToString(file) {
    return new Promise((resolve) => {
      JSZip.loadAsync(file)
        .then(
          async (zip) => {
            let string = '';
            const zipEntry = zip.files['word/document.xml'];
            await zip
              .file(zipEntry.name)
              .async('string')
              .then((data) => {
                const regExpP = /<w:p(.+?)>(.+?)<\/w:p>/g;
                const regExpT = /<w:t>(.+?)<\/w:t>/g;

                data.replace(regExpP, (_, attr, p) => {
                  p.replace(regExpT, ($1, $2) => {
                    string += `${$2}`;
                  });
                  string += '\n';
                });
              })
              .catch(() => {
                resolve('');
              });
            resolve(string);
          },
          () => {
            resolve('');
          },
        )
        .catch(() => {
          resolve('');
        });
    });
  },
  txtToString(file, code = 'utf-8') {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(file, code);
      reader.onload = (res) => resolve(res.target.result);
      reader.onerror = (err) => reject(err);
    });
  },
};

export default utils;
