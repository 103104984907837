import React, { Component } from 'react';

import { Search, WrapperTable } from '../../common';

class ListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.getTableData();
  }

  getTableData = (params) => {
    this.props.onGetTableData(params);
  };

  handleSearchChange = (search) => {
    this.getTableData({ ...search, pageIndex: 1 });
  };

  handleTableChange = (pagination) => {
    this.getTableData(pagination);
  };

  render() {
    const { searchFormItems, search, showSearchRest, tableData, tablePager, columns, showTableIndex } = this.props;

    return (
      <>
        <Search
          onSearch={this.handleSearchChange}
          formItems={searchFormItems}
          initialValues={search}
          showRest={showSearchRest}
        />
        <WrapperTable
          columns={columns}
          dataSource={tableData}
          onTableChange={this.handleTableChange}
          showIndex={showTableIndex}
          tablePager={tablePager}
        />
      </>
    );
  }
}

export default ListPage;
