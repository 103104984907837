import React from 'react';
import classNames from 'classnames';
import loadingImg from '../assets/loading.png';
import './loading.scss';

const Loading = (props) => {
  const { size, className } = props;

  return <img className={classNames('rt-loading', className)} src={loadingImg} style={{ width: size, height: size }} />;
};

Loading.defaultProps = {
  size: 16,
  className: '',
};

export default Loading;
