import React from 'react';
import classNames from 'classnames';
import './icon.scss';
import './iconfont';

const Icon = (props) => {
  const { className, name, size, color, multiColor, style } = props;

  // TODO 大小有问题
  if (multiColor) {
    return (
      <svg
        className={classNames('rticon', className)}
        aria-hidden="true"
        style={{ width: size, height: size, ...style }}
      >
        <use xlinkHref={`#${name}`}></use>
      </svg>
    );
  }

  return <i className={classNames('rticon', className, name)} style={{ fontSize: size, color }} />;
};

Icon.defaultProps = {
  className: '',
  style: {},
  name: '',
  size: 12,
  color: '',
  multiColor: false,
};

export default Icon;
