export const SYS_NAME = process.env.REACT_APP_PAGE_TITLE;

// 系统域名
// export const ONLINE_ORIGIN = 'console.ivh.qq.com';
// export const TEST_ORIGIN = 'virtualhuman.testsite.woa.com';

// aitob域名共用部分
export const AITOB_ORIGIN_COMMON_PART = ['ivh', 'virtualhuman'];

// 当前项目产品模块id
export const ONLINE_PRODUCTID = 6;
export const TEST_PRODUCTID = 6;

// 菜单需展示的产品模块id组
export const ONLINE_MENU_PRODUCTIDS = [
  ONLINE_PRODUCTID, // USER_ADMIN
  10, // VDEMO
  12, // WX_MINI_APP_POC_MANAGEMENT
  11, // INTERFACE_MANAGEMENT
  13, // TRTC_WEB_FEEDBACK,
];

export const TEST_MENU_PRODUCTIDS = [
  TEST_PRODUCTID, // USER_ADMIN
  15, // VDEMO
  16, // WX_MINI_APP_POC_MANAGEMENT
  17, // INTERFACE_MANAGEMENT
  51, // TRTC_WEB_FEEDBACK
];
