import utils from '../../utils';

// TODO
export const RESOURCE_TYPE_HOST = 1;
export const RESOURCE_TYPE_TIMBRE = 2;
export const RESOURCE_TYPE_LINE = 3;
export const RESOURCE_TYPE_TEXT_DRIVE = 4;
export const RESOURCE_TYPE_VOICE_DRIVE = 5;
export const RESOURCE_TYPE_2D = 6;
export const RESOURCE_TYPE_DAOLE = 7;
export const RESOURCE_TYPE_3D = 8;

export const resourceTypeEnum = [
  { key: RESOURCE_TYPE_HOST, name: '主播形象配置' },
  { key: RESOURCE_TYPE_TIMBRE, name: '音色配置' },
  { key: RESOURCE_TYPE_LINE, name: '线路配置' },
  { key: RESOURCE_TYPE_TEXT_DRIVE, name: '文本驱动主播' },
  { key: RESOURCE_TYPE_VOICE_DRIVE, name: '声音驱动主播' },
  { key: RESOURCE_TYPE_2D, name: '2d动画人' },
  { key: RESOURCE_TYPE_3D, name: '3D主播' },
];
export const resourceTypeMap = utils.enumListToMap(resourceTypeEnum);

// 超级管理员商户id
export const SUPER_ADMIN_TENANTID = 1;

// 角色类型
export const ROLE_TYPE_USER_DEFINE = 1;
export const ROLE_TYPE_TENANT_ADMIN = 2;
export const ROLE_TYPE_SUPER_ADMIN = 3;
export const roleTypeEnum = [
  { key: ROLE_TYPE_USER_DEFINE, name: '自定义角色' },
  { key: ROLE_TYPE_TENANT_ADMIN, name: '商户管理员' },
  { key: ROLE_TYPE_SUPER_ADMIN, name: '系统管理员' },
];
export const roleTypeMap = utils.enumListToMap(roleTypeEnum);

// 用户类型
export const USER_TYPE_USER_DEFINE = 0;
export const USER_TYPE_TENANT_ADMIN = 2;
export const USER_TYPE_SUPER_ADMIN = 3;
export const userTypeEnum = [
  { key: USER_TYPE_USER_DEFINE, name: '自定义' },
  { key: USER_TYPE_TENANT_ADMIN, name: '商户预置管理员' },
  { key: USER_TYPE_SUPER_ADMIN, name: '超级管理员' },
];
export const userTypeMap = utils.enumListToMap(userTypeEnum);

// 商户有效状态
export const TENANT_STATUS_VALID = 1;
export const TENANT_STATUS_INVALID = 2;
export const TENANT_STATUS_NOT_INIT = 0;
export const tenantStatusEnum = [
  { key: TENANT_STATUS_VALID, name: '生效中' },
  { key: TENANT_STATUS_INVALID, name: '未生效' },
  { key: TENANT_STATUS_NOT_INIT, name: '未设置' },
];
export const tenantStatusMap = utils.enumListToMap(tenantStatusEnum);
